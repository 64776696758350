import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import Button from "react-bootstrap/Button";
import "../../css/Enquiry.css";
import { useNavigate, useLocation } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "react-datepicker/dist/react-datepicker.css";
import { BsTrash3Fill, BsPencilFill } from "react-icons/bs";
import axiosConfig from "../../services/axiosConfig";
import DatePicker from "react-datepicker";

function Enquiry() {
  const navigate = useNavigate();
  const addNavigateButton = () => {
    navigate("/enquiryManagement");
  };
  // const [name,setName] = useState('');
  const [companyname, setcompanyname] = useState("");
  const [segment, setsegment] = useState("");
  const [street, setStreet] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [StreetLine, setStreetLine] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [Source, setSource] = useState("");
  const [Category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [Name, setname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Productenquired, setProductenquired] = useState("");
  const [Equivalentmodel, setEquivalentmodel] = useState("");
  const [Qty, setQty] = useState("");
  const [Expectedprice, setExpectedprice] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState(
    new Date().toISOString().split("T")[0] // Current date in "yyyy-MM-dd" format
  );
  const [NextFollowup, setNextFollowup] = useState(null);
  const [checkboxValue1, setCheckboxValue1] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const location = useLocation();
  const { enquiryID } = location.state || {}; // Get enquiryID from the passed state
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [OrderValue, setOrderValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [Description, setDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [enquiryProducts, setEnquiryProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [EnquiryID, setEnquiryID] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [Total, setTotal] = useState("");
  const [Notes, setNotes] = useState("");
  const [assigned, setAssigned] = useState("");
  const [user_id, setUserId] = useState(0);

  const [Chalanges, setChalanges] = useState("");
  const [Requirement, setRequirement] = useState("");
  const [stages, setStages] = useState("");
  const [winPossibilities, setWinPossibilities] = useState("");
  const [Competitor, setCompetitor] = useState("");
  const [Priority, setPriority] = useState("");
  const [active, setActive] = useState("Open");
  const [companyId, setCompanyId] = useState("");
  const [companyAddressId, setCompanyAddressId] = useState("");
  const [City, setCity] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [contacts, setContacts] = useState([
    { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
  ]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState(null);

  const openModalWithDelay = () => {
    // Show the loader
    setIsLoaderVisible(true);

    // Set a 5-second delay
    setTimeout(() => {
      setIsLoaderVisible(false); // Hide the loader
      setIsModalOpen(true); // Open the modal
    }, 1000);
  };

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosConfig.get('/authorizedUsers'); 
        setAssignedOptions(response.data); 
      } catch (error) {
        console.error('Error fetching assigned options:', error);
      }
    };

    fetchData();
  }, []);

  const handleSelect = (option) => {
    setAssigned(option.user_name);
    setUserId(option.user_id); // Set the selected user_name
    setDropdownOpen(false); // Close the dropdown
  };

  // State to store form data
  const [formData, setFormData] = useState({
    productName: "",
    productId: "", // Store the selected product ID
    modelNo: "",
    qty: 0,
    orderValue: 0,
    description: "",
  });

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCompanies([]);
    } else {
      const filtered = companies.filter((company) =>
        company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  }, [searchTerm, companies]);

  // Handle company selection
  const handleCompanySelect = (company) => {
    setSearchTerm(company.company_name); // Set the search term to the selected company
    setFilteredCompanies([]); // Clear suggestions
    handleChangetypes({ target: { value: company.company_name } }); // Load the company details
  };

  const handleChangeUser = (event) => {
    const selectedValue = event.target.value;
    setUser(selectedValue);

    // If "New" is selected, reset all fields
    if (selectedValue === "Ford") {
      setSearchTerm("");
      setSource("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setCity("");
      setDistrict("");
      setState("");
      setPincode("");
      setContacts([
        { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
      ]); // Reset contacts array
    }
  };
  // Hide suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#companySearch")) {
        setFilteredCompanies([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Open modal
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosConfig.get("/products");
      const data = response.data; // Axios automatically parses the JSON response
      setProducts(data); // Assuming the API returns an array of products
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleProductChange = (product) => {
    setFormData({
      ...formData,
      productName: product.product_name, // Set product name
      productId: product.product_id, // Set product ID
    });
    setIsDropdownOpen(false); // Close the dropdown once a product is selected
  };

  const handleDropdownClick = () => {
    if (!isDropdownOpen) {
      fetchProducts(); // Fetch products when dropdown is opened
    }
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };

  // Close modal
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };

  // Handle form submission (Add button)
  const handleClose = () => {
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleDateChange = (date) => {
    setNextFollowup(date);
  };

  const handleDelete = (enquiryID) => {
    // Your delete functionality here
    console.log(`Delete enquiry with ID: ${enquiryID}`);
  };

  const handleEdit = (enquiryID) => {
    // Your edit functionality here
    console.log(`Edit enquiry with ID: ${enquiryID}`);
  };
  const [createFileSave, setcreatefileSave] = useState(false);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function company() {
    let companyFile = {
      company_name: companyname,
      segment: segment,
      territory: AreaTerritory,
      company_location: "abc",
      category: type,
      type: user,
    };
    console.log("companyFile:", companyFile);
    // await axiosConfig.post("/add/companies", companyFile).then((response) => {
    //   console.log("response.dataCompany::", response.data.company_id);
    //   contact(response.data.company_id);
    //   address(response.data.company_id);
    //   // setCompanyId(response.data.company_id);
    // });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
    ]);
  };

  const cancelLastContact = () => {
    if (contacts.length > 1) {
      setContacts(contacts.slice(0, -1));
    }
  };

  async function enquiry(companyId, companyAddressId) {
    let enquiryFile = {
      company_id: companyId,
      company_address_id: companyAddressId,
      customer_name: Name,
      userId: user_id,
      email: EmailID, // Populate with state value
      contact: ContactNumber, // Populate with state value
      source: Source,
      total: Total,
      notes: Notes,
      assigned_to: assigned,
      date: NextFollowupAction,
      chalanges: Chalanges,
      requirement_type: Requirement,
      stages: stages,
      win_possibilities: winPossibilities,
      competitor: Competitor,
      priority: Priority,
      status: active,
      products: enquiryProducts,
      next_follow_date: NextFollowup,
    };

    // Check for missing required fields
    const requiredFields = [
      "company_id",
      "company_address_id",
      "customer_name",
      "userId",
      "email",
      "contact",
      "total",
      "notes",
      "assigned_to",
      "date",
      "chalanges",
      "requirement_type",
      "stages",
      "win_possibilities",
      "competitor",
      "priority",
      "status",
      "products",
    ];

    let missingFields = requiredFields.filter(
      (field) => !enquiryFile[field] || enquiryFile[field] === ""
    );

    if (missingFields.length > 0) {
      alert(`Please fill all the required fields: ${missingFields.join(", ")}`);
      return false; // Validation failed
    }

    try {
      const response = await axiosConfig.post(`/add/enquiry`, enquiryFile);

      if (response.data.enquiry_id) {
        let data_enquiry_id = response.data.enquiry_id;
        localStorage.setItem("enquiryId", data_enquiry_id);
        console.log("Enquiry successfully posted:", response.data);
        return true;
      }
    } catch (error) {
      console.error("Error posting enquiry:", error);
      return false;
    }
  }

  let enquiryId = localStorage.getItem("enquiryId");

  useEffect(() => {
    // Check if the action is 'edit' in localStorage
    const action = localStorage.getItem("action");
    if (action === "edit") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    // axiosConfig
    //   .get("/enquirie-product")
    //   .then((response) => {
    //     console.log("Enquiry Products fetched successfully:", response.data);
    //     setEnquiryProducts(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(
    //       "Error fetching enquiry products:",
    //       error.response || error.message
    //     );
    //   });

    const fetchCompanies = async () => {
      try {
        const response = await axiosConfig.get("/companies");
        const sortedCompanies = response.data.sort((a, b) =>
          a.company_name.localeCompare(b.company_name)
        ); // Sort alphabetically
        setCompanies(sortedCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  const handleProductEdit = (serialNo) => {
    // Locate the product using the serial number (index in the array)
    const productToEdit = enquiryProducts[serialNo - 1];

    // Populate the form with the product details
    setFormData({
      productName: productToEdit.product_name,
      productId: productToEdit.product_id,
      modelNo: productToEdit.model_no,
      qty: productToEdit.quantity,
      orderValue: productToEdit.order_value,
      description: productToEdit.description,
    });

    // Save the index for updating
    setSelectedProductIndex(serialNo - 1); // Store the index for the product being edited
    setIsEditing(true);
    setIsModalOpen(true);
  };

  const handleProductDelete = (serialNo) => {
    // Filter out the product by serial number (index)
    const updatedProducts = enquiryProducts.filter(
      (_, index) => index !== serialNo - 1
    );
    setEnquiryProducts(updatedProducts);
  };

  const saveEnquiry = async () => {
    if (isEditMode) {
      console.log("Update enquiry");

      try {
        console.log("NextFollowup:", NextFollowup);
        const formattedDate = NextFollowup
          ? NextFollowup.toISOString().split("T")[0]
          : "";

        // Log the formatted date to ensure it's correct
        console.log("Formatted Date:", formattedDate);

        // Create the body for the PUT request
        const requestBody = {
          customer_name: Name,
          email: EmailID,
          contact: ContactNumber,
          product_enquired: Productenquired,
          status: active,
          total: Total, // Add the Total field
          notes: Notes, // Add the Notes field
          assigned_to: assigned, // Add the Assigned field
          date: NextFollowupAction, // Add the NextFollowupAction field
          chalanges: Chalanges,
          requirement_type: Requirement,
          stages: stages,
          win_possibilities: winPossibilities,
          competitor: Competitor,
          priority: Priority,
          next_follow_up: formattedDate,
          products: enquiryProducts,
        };

        console.log("Request Body:", requestBody);

        // Construct the URL without query parameters
        const url = `/update/${enquiryID}`;

        // Perform the PUT request using axiosConfig
        const response = await axiosConfig.put(url, requestBody);

        // Handle the successful update
        console.log("Update response:", response.data);
        return true;
        // alert("Enquiry updated successfully");
      } catch (error) {
        // Handle any errors during the update
        console.error("Error updating enquiry:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
        alert("Failed to update enquiry. Please try again.");
        return true;
      }
    } else {
      console.log("Create enquiry");

      // Handle add logic here (if this is for creating an enquiry)
      // You may call other functions for creating an enquiry if needed
      await company(); // Example function, you can replace it with actual logic
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const getCompanyDetail = localStorage.getItem("companyDetails");
  // console.log("rajjjjj ", getCompanyDetail);
  const parsedCompanyDetail = JSON.parse(getCompanyDetail);
  // console.log("parsedCompanyDetail::", JSON.parse(getCompanyDetail));

  const handleChangetypes = async (event) => {
    setcompanyname(event.target.value);
    const companyName = event.target.value;
    console.log("handleChangetypesSelectedValue:", companyName);

    try {
      const response = await axiosConfig.get(
        `/companies/details/${companyName}`
      );
      const companyDetails = response.data;

      console.log("companyDetails:", companyDetails);

      if (companyDetails) {
        // Set the company ID and company address ID
        setCompanyId(companyDetails.company_id || "");
        setCompanyAddressId(companyDetails.address?.company_address_id || ""); // Adjust property names if needed

        // Populate contact and other relevant details
        setname(companyDetails.contact?.name || "");
        setEmailID(companyDetails.contact?.email || "");
        setContactNumber(companyDetails.contact?.phone || "");
        setSource(companyDetails.source || ""); // Adjust the key if different in response
        setCity(companyDetails.address?.city || "");

        // Populate the state with the fetched company details (if needed)
        setDoorNo(companyDetails.address?.door_no || "");
        setstreetname(companyDetails.address?.street_name || "");
        setStreetLine(companyDetails.address?.street_line_2 || "");
        setDistrict(companyDetails.address?.district || "");
        setAreaTerritory(companyDetails.territory || "");
        setState(companyDetails.address?.state || "");
        setPincode(companyDetails.address?.postal_code || "");
        setsegment(companyDetails.segment || "");

        // Populate contact details
        const newContact = {
          Name: companyDetails.contact?.name || "",
          Designation: companyDetails.contact?.designation || "",
          EmailID: companyDetails.contact?.email || "",
          ContactNumber: companyDetails.contact?.phone || "",
        };

        // Update the state for contacts if needed
        setContacts([newContact]);
      } else {
        // Optionally clear input fields if no data is found
        // clearAllInputs();
      }
    } catch (error) {
      console.error("Error fetching company details: ", error);
      // Optionally clear input fields on error
      // clearAllInputs();
    }
  };

  const handleAddProduct = () => {
    const updatedProduct = {
      product_name: formData.productName,
      product_id: formData.productId,
      model_no: formData.modelNo,
      quantity: formData.qty,
      order_value: formData.orderValue,
      description: formData.description,
    };

    let updatedProducts;

    if (isEditing) {
      // Update the product at the specific index
      updatedProducts = enquiryProducts.map((product, index) =>
        index === selectedProductIndex ? updatedProduct : product
      );
    } else {
      // Add a new product to the array
      updatedProducts = [...enquiryProducts, updatedProduct];
    }

    // Update state
    setEnquiryProducts(updatedProducts);

    // Clear the form and reset state
    setFormData({
      productName: "",
      productId: "",
      modelNo: "",
      qty: 0,
      orderValue: 0,
      description: "",
    });
    setIsEditing(false);
    setSelectedProductIndex(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("useEffect called.");

    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      // Fetch enquiry details
      axiosConfig
        .get(`/enquiries/details/${enquiryID}`)
        .then((response) => {
          const data = response.data;
          setEnquiryDetails(data); // Store the response data if needed
          setEnquiryProducts(data.products || []);

          // Populate contacts from the response
          if (data) {
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setUser(data.customer_name || "");
            setEmailID(data.email || "");
            setContactNumber(data.contact || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");

            // Address details
            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_name || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setSource(address.source || "");
            }

            // Contact details
            const contact = data.masterCompany?.contact;
            if (contact) {
              setContacts([
                {
                  // Ensure contacts is an array
                  Name: contact.name || "",
                  Designation: contact.designation || "",
                  EmailID: contact.email || "",
                  ContactNumber: contact.phone || "",
                  Department: contact.department || "",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching enquiry details:", error);
        });
    }
  }, [enquiryID]);

  return (
    <div>
    <Header />
    <form>
      <h2 className="basic-info-title">Basic Information</h2>

      <div className="basic-info-container">
        {/* Row 1 */}
        <div className="basic-info-row">
          <label className="basic-info-label">
            Company Name<span className="required">*</span>
          </label>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search company name"
            className="basic-info-input"
          />
          {filteredCompanies.length > 0 && (
            <ul className="basic-info-dropdown-list">
              {filteredCompanies.map((company) => (
                <li
                  key={company.company_id}
                  onClick={() => handleCompanySelect(company)}
                  className="basic-info-dropdown-item"
                >
                  {company.company_name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">Category</label>
          <select
            value={user}
            onChange={handleChangeUser}
            className="basic-info-input"
          >
            <option value="Existing">Existing</option>
            <option value="New">New</option>
          </select>
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">Source</label>
          <select
            value={Source}
            onChange={(e) => setSource(e.target.value)}
            className="basic-info-input"
          >
            <option value="Walk">Walk-in</option>
            <option value="Mail">Mail</option>
            <option value="Expo">Expo</option>
            <option value="Web">Website</option>
          </select>
        </div>

        {/* Row 2 */}
        <div className="basic-info-row">
          <label className="basic-info-label">
            Door No<span className="required">*</span>
          </label>
          <input
            type="text"
            value={DoorNo}
            onChange={(e) => setDoorNo(e.target.value)}
            required
            className="basic-info-input"
          />
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">Street Line-1</label>
          <input
            type="text"
            value={streetname}
            onChange={(e) => setstreetname(e.target.value)}
            className="basic-info-input"
          />
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">Street Line-2</label>
          <input
            type="text"
            value={StreetLine}
            onChange={(e) => setStreetLine(e.target.value)}
            className="basic-info-input"
          />
        </div>

        {/* Row 3 */}
        <div className="basic-info-row">
          <label className="basic-info-label">City</label>
          <input
            type="text"
            value={City}
            onChange={(e) => setCity(e.target.value)}
            className="basic-info-input"
          />
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">District</label>
          <input
            type="text"
            value={District}
            onChange={(e) => setDistrict(e.target.value)}
            className="basic-info-input"
          />
        </div>
        <div className="basic-info-row">
          <label className="basic-info-label">State</label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="basic-info-input"
          />
        </div>

        {/* Row 4 - Pincode */}
        <div className="basic-info-row">
          <label className="basic-info-label">Pincode</label>
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            className="basic-info-input"
          />
        </div>
      </div>
    </form>

    {contacts.map((contact, index) => (
      <form key={index}>
        <h2 className="basic-info-title">
          Customer Contact Person Details {index + 1}
        </h2>

        <div className="basic-info-container">
          {/* Row 1: Name */}
          <div className="basic-info-row">
            <label className="basic-info-label">Name</label>
            <input
              type="text"
              value={contact.Name}
              onChange={(e) =>
                handleContactChange(index, "Name", e.target.value)
              }
              className="basic-info-input"
            />
          </div>

          {/* Row 2: Designation */}
          <div className="basic-info-row">
            <label className="basic-info-label">Designation</label>
            <input
              type="text"
              value={contact.Designation}
              onChange={(e) =>
                handleContactChange(index, "Designation", e.target.value)
              }
              className="basic-info-input"
            />
          </div>

          {/* Row 3: Email ID */}
          <div className="basic-info-row">
            <label className="basic-info-label">Email ID</label>
            <input
              type="text"
              value={contact.EmailID}
              onChange={(e) =>
                handleContactChange(index, "EmailID", e.target.value)
              }
              className="basic-info-input"
            />
          </div>

          {/* Row 4: Contact Number */}
          <div className="basic-info-row">
            <label className="basic-info-label">Contact Number</label>
            <input
              type="text"
              value={contact.ContactNumber}
              onChange={(e) =>
                handleContactChange(index, "ContactNumber", e.target.value)
              }
              className="basic-info-input"
            />
          </div>
        </div>

        {/* Optionally, you can add an "Add Another Contact" button here */}
      </form>
    ))}

    <div>
      <h2 className="basic-info-title">Enquiry Classification</h2>
      <div className="enquiry-container">
        {/* Row 1: 3 columns */}
        <div className="row-field">
          <label className="basic-info-label" htmlFor="requirementType">
            Requirement Type
          </label>
          <select
            id="requirementType"
            value={Requirement}
            onChange={(e) => setRequirement(e.target.value)}
            className="basic-info-input"
          >
            <option value="New">New</option>
            <option value="NCrossew">Cross-selling</option>
            <option value="UpSel">Up-selling</option>
            <option value="Exact">Exact Sale</option>
          </select>
        </div>

        <div className="row-field">
          <label className="basic-info-label" htmlFor="stages">
            Stages
          </label>
          <select
            id="stages"
            value={stages}
            onChange={(e) => setStages(e.target.value)}
            className="basic-info-input"
          >
            <option value="scope">Scope</option>
            <option value="design">Design</option>
            <option value="Procure">Procurement</option>
            <option value="Implement">Implementation</option>
            <option value="break">Breakdown</option>
            <option value="Spare">Spare</option>
          </select>
        </div>

        <div className="row-field">
          <label className="basic-info-label" htmlFor="priority">
            Priority
          </label>
          <select
            id="priority"
            value={Priority}
            onChange={(e) => setPriority(e.target.value)}
            className="basic-info-input"
          >
            <option value="low">Low</option>
            <option value="Medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>

        {/* Row 2: 3 columns */}
        <div className="row-field">
          <label className="basic-info-label" htmlFor="competitor">
            Competitor
          </label>
          <input
            type="text"
            id="competitor"
            value={Competitor}
            onChange={(e) => setCompetitor(e.target.value)}
            className="basic-info-input"
          />
        </div>

        <div className="row-field">
          <label className="basic-info-label" htmlFor="winPossibilities">
            Win Possibilities
          </label>
          <select
            id="winPossibilities"
            value={winPossibilities}
            onChange={(e) => setWinPossibilities(e.target.value)}
            className="basic-info-input"
          >
            <option value="50">50%</option>
            <option value="60">60%</option>
            <option value="70">70%</option>
            <option value="80">80%</option>
            <option value="90">90%</option>
            <option value="100">100%</option>
          </select>
        </div>

        <div className="row-field">
          <label className="basic-info-label" htmlFor="chalanges">
            Chalanges
          </label>
          <input
            type="text"
            id="chalanges"
            value={Chalanges}
            onChange={(e) => setChalanges(e.target.value)}
            className="basic-info-input"
          />
        </div>
      </div>
    </div>

    <form>
      <h2 className="basic-info-title">Product Details</h2>
      <div className="product-container">
        <div className="table-container">
          <table className="product-table">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Product Name</th>
                <th>Model No</th>
                <th>Qty</th>
                <th>Order Value</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {enquiryProducts.map((product, index) => (
                <tr key={product.enquiry_product_id}>
                  <td>{index + 1}</td>
                  <td>{product.product_name}</td>
                  <td>{product.model_no}</td>
                  <td>{product.quantity}</td>
                  <td>{product.order_value}</td>
                  <td>{product.description}</td>
                  <td>
                    <BsTrash3Fill
                      className="trash-icon"
                      onClick={() =>
                        handleProductDelete(product.enquiry_product_id)
                      }
                    />
                    <BsPencilFill
                      className="edit-icon"
                      onClick={() => handleProductEdit(index + 1)} // Pass serial number
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="total-container">
          <label className="basic-info-label">Total</label>
          <input
            type="text"
            value={Total}
            onChange={(e) => setTotal(e.target.value)}
            className="basic-info-input"
          />
        </div>
        <button
          type="button"
          onClick={openModalWithDelay}
          className="add-product-button"
        >
          Add Product
        </button>

        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-container">
              <div className="modal-header">
                <h2 className="modal-title">Add Product</h2>
                <div className="close-modal" onClick={handleClose}>
                  ×
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label className="form-label">Product</label>
                  <input
                    onClick={handleDropdownClick}
                    type="text"
                    value={formData.productName}
                    onChange={handleProductChange}
                    className="input-field"
                    placeholder="Select a product"
                    readOnly
                  />
                  <span className="dropdown-icon">▼</span>{" "}
                  {isDropdownOpen && (
                    <div className="dropdown-list-1">
                      {products.map((product) => (
                        <div
                        key={product.id}
                        onClick={() => handleProductChange(product)}
                          className="dropdown-item"
                        >
                            {product.product_name}
                            </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="form-label">Model No</label>
                  <input
                    type="text"
                    name="modelNo"
                    value={formData.modelNo}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Quantity</label>
                  <input
                    type="number"
                    name="qty"
                    value={formData.qty}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Order Value</label>
                  <input
                    type="number"
                    name="orderValue"
                    value={formData.orderValue}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="textarea-field"
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="submit"
                    onClick={handleAddProduct}
                    className="submit-button"
                  >
                    {isEditing ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </form>

    <form>
  <h2 className="basic-info-title">Enquiry Details</h2>
  <div className="basic-info-container ">
    {/* Row 1: 3 columns */}
    <div className="enquiry-field">
      <label htmlFor="name1" className="enquiry-label">
        Date
      </label>
      <input
        type="text"
        value={NextFollowupAction}
        onClick={() => {
          const today = new Date();
          const formattedDate = today.toISOString().split("T")[0];
          setNextFollowupAction(formattedDate);
        }}
        readOnly
        className="enquiry-input"
      />
    </div>

    <div className="enquiry-field">
      <label htmlFor="name2" className="enquiry-label">
        Assigned To
      </label>
      <input
        type="text"
        id="name4"
        value={assigned}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        readOnly
        placeholder="Select an assignee"
        className="enquiry-input"
      />
      {dropdownOpen && (
        <div className="dropdown-list2">
          {assignedOptions.map((option) => (
            <div
              key={option.user_id}
              onClick={() => handleSelect(option)}
              className="dropdown-item2"
            >
              {option.user_name}
            </div>
          ))}
        </div>
      )}
    </div>

    <div className="enquiry-field">
      <label htmlFor="next-followup" className="enquiry-label">
        Next Follow-Up
      </label>
      <DatePicker
        selected={NextFollowup}
        onChange={(date) => setNextFollowup(date)}
        dateFormat="yyyy-MM-dd"
        placeholderText="Select a date"
        id="next-followup"
        className="enquiry-input"
      />
    </div>

    {/* New Note Input Field */}
    <div className="enquiry-field">
      <label htmlFor="note" className="enquiry-label">
        Note
      </label>
      <textarea
        id="note"
        value={Notes}
        onChange={(e) => setNotes(e.target.value)}
        placeholder="Enter your note"
        className="enquiry-area"
        rows="4"
      />
    </div>
  </div>
</form>
<div
      className="enquiry-buttons"
    >
      <Button
        variant="primary"
        className="enquiry-primary"
        onClick={async () => {
          setcreatefileSave(false); // Hide dialog initially
          let isSuccess = false;
          if (isEditMode) {
            isSuccess = await saveEnquiry(); // Call update logic for edit mode
          } else {
            isSuccess = await enquiry(companyId, companyAddressId); // Call create logic for new enquiry
          }
          if (isSuccess) {
            setcreatefileSave(true); // Show dialog only if the operation is successful
          }
        }}
      
      >
  {isEditMode ? "Update" : "Submit"}
  </Button>
    </div>

    <Dialog
     open={createFileSave}
     onClose={() => setcreatefileSave(false)}
      PaperProps={{
        className: "enquiry-dialog-paper"
      }}
    >
      <DialogContent
        dividers
        className="enquiry-dialog-content"
      >
        {/* Success Icon */}
        <img
          src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
          alt="Success Icon"
          className="enquiry-success-icon"        />
        <span  className="success-message">
        Enquiry ID: {localStorage.getItem("enquiryId")} Submitted Successfully...!!!
        </span>
      </DialogContent>
      <DialogActions
        className="enquiry-dialog-actions"
      >
        <Button
          onClick={addNavigateButton}
      variant="contained"
        className="enquiry-ok-button"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>

    <div
      className="reset-but"
    >
      <Button
        variant="primary"
       className="enquiry-reset-btn"
        onClick={() => {
          // clearAllInputs();
        }}
      >
        Reset
      </Button>
    </div>

    </div>
  );
}
export default Enquiry;
