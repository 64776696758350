import React, { useState, useEffect } from 'react';
import '../../css/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import '../../css/EnquiryManagement.css';
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsCurrencyRupee,
  BsChevronDown,
} from 'react-icons/bs';
import { MdBusiness, MdPerson } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaIdBadge, FaCalendarAlt, FaTasks } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import axiosConfig from '../../services/axiosConfig';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Header from '../../home/Header';

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ marginLeft: 5, color: '#FFFFFF' }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: '5px', color: '#FFFFFF' }} />
  </div>
);

function EnquiryManagement() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  
  
  useEffect(() => {
    axiosConfig
      .get('/enquiries')
      .then((response) => {
        const formattedData = response.data
          .filter((item) => item.status !== 'deleted')
          .map((item, index) => {
            const enquiryID = `${item.enquiry_id || index + 1}`; // Prefix 'Enq'

            return {
              id: index + 1, // Serial number for table rows
              enquiryID, // Enquiry ID with 'Enq' prefix
              Company_Name: item.masterCompany?.company_name || 'Unknown Company',
              Name: item.masterCompany?.contact?.name || 'N/A',
              Mobile_No: item.masterCompany?.contact?.phone || 'N/A',
              Email_Id: item.masterCompany?.contact?.email || 'N/A',
              ExpectedPrice: item.total ? `₹${Number(item.total).toLocaleString()}` : 'N/A',
              Created_Date: item.date || 'N/A',
              status: item.status || 'N/A',
            };
          });
        setProducts(formattedData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const addNavigateButton = () => {
    localStorage.setItem('action', 'add');
    navigate('/enquiry');
  };

  const handleEdit = (enquiryID) => {
    localStorage.setItem('action', 'edit');
    localStorage.setItem('enquiryID', enquiryID);
    navigate('/enquiry', { state: { enquiryID } });
  };

  const handleDelete = (enquiryID) => {
    axiosConfig
      .put(`/delete/${enquiryID}?status=deleted`)
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.enquiryID !== enquiryID)
        );
      })
      .catch((error) => {
        console.error("Error deleting enquiry:", error);
      });
  };

  const columns = [
    {
      dataField: 'id',
      text: 'S.No',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '40px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<AiFillInfoCircle />} title="Sn" tooltipText="Serial Number" />
      ),
    },
    {
      dataField: 'enquiryID',
      text: 'Enquiry ID',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaIdBadge />} title="EnqID" tooltipText="Enquiry ID" />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" prefix to the enquiryID value
    },
    {
      dataField: 'Company_Name',
      text: 'Company Name',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdBusiness />} title="Comp Name" tooltipText="Company Name" />
      ),
    },
    {
      dataField: 'Name',
      text: 'Contact Person',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdPerson />} title="Cont Per" tooltipText="Contact Person" />
      ),
    },
    {
      dataField: 'Mobile_No',
      text: 'Mobile',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '60px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsTelephone />} title="Mobile" tooltipText="Mobile Number" />
      ),
    },
    {
      dataField: 'Email_Id',
      text: 'Email',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
      style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Email" tooltipText="Email ID" />
      ),
    },
    {
      dataField: 'ExpectedPrice',
      text: 'Order Value',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsCurrencyRupee />} title="Order" tooltipText="Order Value" />
      ),
    },
    
    
    {
      dataField: 'Created_Date',
      text: 'Created Date',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '40px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaCalendarAlt />} title="Date" tooltipText="Created Date" />
      ),
    },
    {
      dataField: 'status', // Use the field in your data for the status
      text: 'Status', // Column header text
      sort: true, // Allow sorting by status
      headerStyle: {
        backgroundColor: '#243A73',
        color: '#FFFFFF',
        fontSize: '12px',
        width: '80px' // Adjust width for the status column
      },
      style: { textAlign: 'center', whiteSpace: 'nowrap' }, // Style for column cells
      formatter: (cell) => (
        <span style={{ textAlign: 'center', padding: '4px', borderRadius: '4px' }}>
          {cell || 'N/A'} {/* Display the status value or 'N/A' if undefined */}
        </span>
      ),
    },    
    {
      dataField: 'Actions',
      text: 'Actions',
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { whiteSpace: 'nowrap', textAlign: 'center' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaCalendarAlt />} title="Actions" tooltipText="Actions" />
      ),
      formatter: (cell, row) => (
        <div>
          <BsTrash3Fill
            style={{ color:   "linear-gradient(to right, #0B5394, #00619A)", cursor: 'pointer' }}
            onClick={() => handleDelete(row.enquiryID)}
          />
          <BsPencilFill
            style={{ color: "linear-gradient(to right, #0B5394, #00619A)", cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => handleEdit(row.enquiryID)}
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
     // Optionally, you can add a custom class here
     paginationClassName: 'custom-pagination-container',
  });

  return (
    <div>
      <Header />
      <div style={{ marginLeft: '-1%', marginTop: '10%', width: '100%' }}>
        <div className="box">
          <Button
            variant="primary"
            style={{ marginLeft: '1085px',   background: "linear-gradient(to right, #0B5394, #00619A)",marginTop:'-230px' }}
            onClick={addNavigateButton}
          >
            <BsPlusCircleFill style={{ marginRight: '3px' }} />
            Add Enquiry
          </Button>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={products}
            columns={columns}
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
}

export default EnquiryManagement;