import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const axiosConfig = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to set the Authorization header dynamically
axiosConfig.interceptors.request.use((config) => {
  const token = localStorage.getItem('authToken'); // Get token before each request
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

export default axiosConfig;
