import React, { useState, useEffect } from 'react';
import '../../css/Header.css';
import Header from '../../home/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import '../../css/EnquiryManagement.css';
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsCurrencyRupee,
  BsChevronDown,
} from 'react-icons/bs';
import { MdBusiness, MdPerson } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaIdBadge, FaCalendarAlt } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import axiosConfig from '../../services/axiosConfig';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {icon}
          <span style={{ marginLeft: 5, color: '#FFFFFF' }}>{title}</span>
        </div>
      </OverlayTrigger>
      <BsChevronDown style={{ marginLeft: '5px', color: '#FFFFFF' }} />
    </div>
);

function FollowUpManagement() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
      // Get the email from localStorag
      const userId = localStorage.getItem('loggedInUserId');
  
      if (userId) {
        // Use the userEmail in the API request
        axiosConfig
          .get(`/enquiries/userId/${userId}`)
          .then((response) => {
            const formattedData = response.data
              .filter((item) => item.status !== 'deleted')
              .map((item, index) => {
                const enquiryID = `${item.enquiry_id || index + 1}`; // Prefix 'Enq'
                return {
                  id: index + 1, // Serial number for table rows
                  enquiryID, // Enquiry ID with 'Enq' prefix
                  Company_Name: item.masterCompany?.company_name || 'Unknown Company',
                  Name: item.masterCompany?.contact?.name || 'N/A',
                  Mobile_No: item.masterCompany?.contact?.phone || 'N/A',
                  Email_Id: item.email || 'N/A',
                  ExpectedPrice: item.total ? `₹${Number(item.total).toLocaleString()}` : 'N/A',
                  Created_Date: item.date || 'N/A',
                  Status: item.status || 'N/A',
                  Requirement_Type: item.requirement_type || 'N/A',
                  Assigned_To: item.assigned_to || 'N/A',
                  Next_Follow_Date: item.next_follow_date || 'N/A',
                  Priority: item.priority || 'N/A',
                  Competitor: item.competitor || 'N/A',
                  Win_Possibilities: item.win_possibilities || 'N/A',
                  Challenges: item.chalanges || 'N/A',
                  Notes: item.notes || 'N/A',
                };
              });
            setProducts(formattedData);
          })
          .catch((error) => console.error('Error fetching data:', error));
      } else {
        console.error('User email not found in localStorage');
      }
    }, []);

  const addNavigateButton = () => {
    localStorage.setItem('action', 'add');
    navigate('/followup');
  };

  const handleEdit = (enquiryID) => {
    localStorage.setItem('action', 'edit');
    localStorage.setItem('enquiryID', enquiryID);
    navigate('/followup', { state: { enquiryID } });
  };

  const handleDelete = (enquiryID) => {
    axiosConfig
      .put(`/delete/${enquiryID}?status=deleted`)
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.enquiryID !== enquiryID)
        );
      })
      .catch((error) => console.error('Error deleting enquiry:', error));
  };

  const columns = [
    {
      dataField: 'id',
      text: 'S.No',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '40px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<AiFillInfoCircle />} title="Sn" tooltipText="Serial Number" />
      ),
    },
    {
      dataField: 'enquiryID',
      text: 'Enquiry ID',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaIdBadge />} title="EnqID" tooltipText="Enquiry ID" />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" prefix to the enquiryID value
    },    
    {
      dataField: 'Company_Name',
      text: 'Company Name',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdBusiness />} title="Comp Name" tooltipText="Company Name" />
      ),
    },
    {
      dataField: 'Name',
      text: 'Contact Person',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdPerson />} title="Cont Per" tooltipText="Contact Person" />
      ),
    },
    {
      dataField: 'Mobile_No',
      text: 'Mobile',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '60px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsTelephone />} title="Mobile" tooltipText="Mobile Number" />
      ),
    },
   
    {
      dataField: 'ExpectedPrice',
      text: 'Order Value',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { textAlign: 'center', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsCurrencyRupee />} title="Order" tooltipText="Order Value" />
      ),
    },
    {
      dataField: 'Priority',
      text: 'Priority',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
      style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Priority" tooltipText="Priority" />
      ),
    },
    {
      dataField: 'Status',
      text: 'Status',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
      style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Escalation Status" tooltipText="Status" />
      ),
    },

  //   {
  //     dataField: 'Age',
  //     text: 'Age',
  //     sort: true,
  //     headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
  //     style: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', padding: '8px' },
  //     headerFormatter: () => (
  //       <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Age" tooltipText="Age" />
  //     ),
  //   },
    {
      dataField: 'Next_Follow_Date',
      text: 'Next_Follow_Date',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '40px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaCalendarAlt />} title="Date" tooltipText="Next_Follow_Date" />
      ),
    },

    {
      dataField: 'Assigned_To',
      text: 'Assigned_To',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '40px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaCalendarAlt />} title="Assigned" tooltipText="Assigned_To" />
      ),
    },

    {
      dataField: 'Actions',
      text: 'Actions',
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '50px' },
      style: { whiteSpace: 'nowrap', textAlign: 'center' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaCalendarAlt />} title="Actions" tooltipText="Actions" />
      ),
      formatter: (cell, row) => (
        <div>
          <BsTrash3Fill
            style={{ color: '#7C3E66', cursor: 'pointer' }}
            onClick={() => handleDelete(row.enquiryID)}
          />
          <BsPencilFill
            style={{ color: '#7C3E66', cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => handleEdit(row.enquiryID)}
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div>
      <Header />
      <div style={{ marginLeft: '-1%', marginTop: '10%', width: '100%' }}>
        <div className="box">           
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={products}
            columns={columns}
            pagination={pagination}
          />
        </div>
      </div>
    </div>
  );
  }

export default FollowUpManagement;