import React, { useState, useEffect } from 'react';
import '../../css/Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Form, Button } from 'react-bootstrap';

import '../../css/CustomerManagement.css';
import {
  BsPlusCircleFill,
  BsTrash3Fill,
  BsPencilFill,
  BsFillEnvelopeFill,
  BsTelephone,
  BsCurrencyRupee,
  BsChevronDown,
} from 'react-icons/bs';
import { MdBusiness, MdPerson } from 'react-icons/md';
import { AiFillInfoCircle } from 'react-icons/ai';
import { FaIdBadge, FaCalendarAlt, FaTasks } from 'react-icons/fa';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import axiosConfig from '../../services/axiosConfig';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Header from '../../home/Header';

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ marginLeft: 5, color: '#FFFFFF' }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: '5px', color: '#FFFFFF' }} />
  </div>
);

function CompanyManagement() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/company');
  };
  const apiUrl = process.env.REACT_APP_API_URL;
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    company: "",
    category: "",
    source: "",
    doorNo: "",
    streetLine1: "",
    streetLine2: "",
    area: "",
    district: "",
    state: "",
    pincode: "",
    phone: "",
  });
 

 
  const handleShowModal = () => setShowModal(true);

  const handleCloseModal = () => setShowModal(false);

  const [contacts, setContacts] = useState([
    { contactName: '', designation: '', mobile: '', mailID: '' },
  ]);

  const loadContacts = () => {
    const savedContacts = JSON.parse(localStorage.getItem('contacts'));
    return savedContacts ? savedContacts : [{ contactName: '', designation: '', mobile: '', mailID: '' }];
  };

 

  // Handle input change for any contact
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedContacts = [...contacts];
    updatedContacts[index][name] = value;
    setContacts(updatedContacts);

    // Save updated contacts to localStorage
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
  };

  // Add another contact
  const addContact = () => {
    const updatedContacts = [
      ...contacts,
      { contactName: '', designation: '', mobile: '', mailID: '' },
    ];
    setContacts(updatedContacts);
    
    // Save updated contacts to localStorage
    localStorage.setItem('contacts', JSON.stringify(updatedContacts));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log("Submitted data:", formData);
    // Add your submission logic here
    handleCloseModal();
  };

  
  useEffect(() => {
    axiosConfig
      .get('/enquiries')
      .then((response) => {
        const formattedData = response.data
          .filter((item) => item.status !== 'deleted')
          .map((item, index) => {
            const enquiryID = `${item.enquiry_id || index + 1}`; // Prefix 'Enq'
            return {
              id: index + 1, // Serial number for table rows
              enquiryID, // Enquiry ID with 'Enq' prefix
              Company_Name: item.masterCompany?.company_name || 'N/A',
              Name: item.masterCompany?.contact?.name || 'N/A',
              Mobile_No: item.masterCompany?.contact?.phone || 'N/A',
              Email_Id: item.email || 'N/A',
              ExpectedPrice: item.total ? `₹${Number(item.total).toLocaleString()}` : 'N/A',
              Created_Date: item.date || 'N/A',
            };
          });
        setProducts(formattedData);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const addNavigateButton = () => {
    localStorage.setItem('action', 'add');
    navigate('/company');
  };

  const handleEdit = (enquiryID) => {
    localStorage.setItem('action', 'edit');
    localStorage.setItem('enquiryID', enquiryID);
    navigate('/company', { state: { enquiryID } });
  };

  const handleDelete = (enquiryID) => {
    axiosConfig
      .put(`${apiUrl}/delete/${enquiryID}?status=deleted`)
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.enquiryID !== enquiryID)
        );
      })
      .catch((error) => console.error('Error deleting enquiry:', error));
  };

  const columns = [
    {
      dataField: 'Company_Name',
      text: 'Company Name',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdBusiness />} title="Comp Name" tooltipText="Company Name" />
      ),
    },
    {
      dataField: 'Industry_Type',
      text: 'Industry Type',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '100px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<AiFillInfoCircle />} title="Industry" tooltipText="Industry Type" />
      ),
    },
    {
      dataField: 'Company_Phone',
      text: 'Company Phone',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsTelephone />} title="Comp Phone" tooltipText="Company Phone" />
      ),
    },
    {
      dataField: 'Contact_Name',
      text: 'Contact Name',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<MdPerson />} title="Contact" tooltipText="Contact Name" />
      ),
    },
    {
      dataField: 'Job_Title',
      text: 'Job Title',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaIdBadge />} title="Job" tooltipText="Job Title" />
      ),
    },
    {
      dataField: 'Email_Address',
      text: 'Email Address',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '120px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsFillEnvelopeFill />} title="Email" tooltipText="Email Address" />
      ),
    },
    {
      dataField: 'Phone_Number',
      text: 'Phone Number',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsTelephone />} title="Phone" tooltipText="Phone Number" />
      ),
    },
    {
      dataField: 'Actions',
      text: 'Actions',
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaTasks />} title="Actions" tooltipText="Actions" />
      ),
      formatter: (cell, row) => (
        <div>
          <BsTrash3Fill
            style={{ color: 'linear-gradient(to right, #0B5394, #00619A)', cursor: 'pointer' }}
            onClick={() => handleDelete(row.enquiryID)}
          />
          <BsPencilFill
            style={{
              color: 'linear-gradient(to right, #0B5394, #00619A)',
              cursor: 'pointer',
              marginLeft: '10px',
            }}
            onClick={() => handleEdit(row.enquiryID)}
          />
        </div>
      ),
    },
  ];
  
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
     // Optionally, you can add a custom class here
     paginationClassName: 'custom-pagination-container',
  });

  return (
    <div>
      <Header />
      <div style={{ marginLeft: '-1%', marginTop: '10%', width: '120%' }}>
        <div className="box">
        <Button
  variant="primary"
  style={{
    marginLeft: '1015px',
    background: 'linear-gradient(to right, #0B5394, #00619A)',
    marginTop: '-230px',
    width: '200px',
    
  }}
  onClick={handleNavigate}
>
  <BsPlusCircleFill style={{ marginRight: '8px' }} /> {/* Reduced margin */}
  Add New Company
</Button>

          <BootstrapTable
            bootstrap4
            keyField="id"
            data={products}
            columns={columns}
            pagination={pagination}
          />
        </div>
      </div>

      </div>
     
     
     

    
  );
};

export default CompanyManagement;