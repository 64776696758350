import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import Button from "react-bootstrap/Button";
import { Modal, Form } from "react-bootstrap";
import "../../css/Closure.css";
import {
  BsPencilFill,
  BsTrash3Fill,
  BsChevronDown,
  BsCurrencyRupee,
  BsEyeFill,
} from "react-icons/bs";
import { MdOutlineSource } from "react-icons/md";
import { MdBusiness, MdPerson } from "react-icons/md";
import { IoMdEye } from "react-icons/io";
import { CgNotes } from "react-icons/cg";
import { FaIdBadge, FaCalendarAlt } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";
import axios from "axios";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axiosConfig from "../../services/axiosConfig";

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div
    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
  >
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {icon}
        <span style={{ marginLeft: 5, color: "#FFFFFF" }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: "5px", color: "#FFFFFF" }} />
  </div>
);

function Closure() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [showCloseModal, setShowCloseModal] = useState(false);
  const [notes, setNotes] = useState("");
  const maxCharacters = 300;
  const [selectedEnquiryId, setSelectedEnquiryId] = useState(null);
  const [status, setStatus] = useState("");
  const [nextFollowDate, setNextFollowDate] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);

  const handleRowSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows([row.id]);
      setSelectedEnquiryId(row.enquiryID); // Ensure this is setting the correct enquiry ID
    } else {
      setSelectedRows([]);
      setSelectedEnquiryId(null); // Reset the selected enquiry ID if row is deselected
    }
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleView = (enquiryID) => {
    console.log("View details for enquiry ID:", enquiryID);
    // You can add your logic to show the details of the enquiry
  };

  // Function to handle opening and closing the modal
  const handleCloseModal = () => setShowCloseModal(!showCloseModal);

  const handleSave = () => {
    // Ensure that a row has been selected
    if (!selectedEnquiryId) {
      alert("Please select an enquiry first.");
      return;
    }

    // Get the current date in the format you need (e.g., YYYY-MM-DD)
    const currentDate = new Date().toISOString().split("T")[0]; // Get the date part (YYYY-MM-DD)

    // Prepare the updated enquiry details
    const updatedEnquiry = {
      status: status,
      notes: notes,
      next_follow_date: currentDate, // Set the current date here
    };

    // Update the enquiry with the selected enquiryId
    axiosConfig
      .put(`/update/${selectedEnquiryId}`, updatedEnquiry)
      .then((response) => {
        console.log("Enquiry updated successfully:", response.data);
        handleCloseModal(); // Close the modal on successful save
        window.location.reload(); // Reload the screen to reflect changes
      })
      .catch((error) => {
        console.error("Error updating enquiry:", error);
      });
  };

  useEffect(() => {
    axiosConfig
      .get(`/enquiries`)
      .then((response) => {
        const formattedData = response.data
          .filter((item) => item.status !== "deleted") // Filter out deleted enquiries
          .map((item, index) => ({
            id: item.enquiry_id || index + 1,
            enquiryID: item.enquiry_id,
            companyName: item.masterCompany?.company_name || "N/A",
            contactPerson: item.masterCompanyContact?.name || "N/A",
            Next_Follow_Date: item.next_follow_date || "N/A",
            orderValue: item.products?.[0]?.order_value || "N/A",
            source: item.masterCompany?.address?.source || "N/A",
            assignedTo: item.authorizedUser?.user_name || "N/A",
            notes: item.notes || "No Notes",
            status: item.status || "N/A",
          }));
  
        setProducts(formattedData); // Update the state with the filtered data
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const handleEdit = (enquiryID) => {
    localStorage.setItem("action", "edit");
    localStorage.setItem("enquiryID", enquiryID);
    navigate("/enquiry", { state: { enquiryID } });
  };

  // Delete function
  const handleDelete = (enquiryID) => {
    axios
      .put(`${apiUrl}/delete/${enquiryID}?status=deleted`)
      .then(() => {
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.enquiryID !== enquiryID)
        );
      })
      .catch((error) => {
        console.error("Error deleting enquiry:", error);
      });
  };

  const columns = [
    {
      dataField: "enquiryID",text: "Enquiry ID",sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaIdBadge />}
          title="EnqID"
          tooltipText="Enquiry ID"
        />
      ),
      formatter: (cell) => `ENQ${cell}`, // Add "ENQ" before the enquiryID value
    },
    {
      dataField: "Next_Follow_Date",
      text: "Next_Follow_Date",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
        width: "100px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt />}
          title="Date"
          tooltipText="Next_Follow_Date"
        />
      ),
    },
    {
      dataField: "companyName",
      text: "Company Name",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdBusiness />}
          title="Comp Name"
          tooltipText="Company Name"
        />
      ),
    },
    {
      dataField: "contactPerson",
      text: "Contact Person",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdPerson />}
          title="Cont Per"
          tooltipText="Contact Person"
        />
      ),
    },
    {
      dataField: "orderValue",
      text: "Order Value",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<BsCurrencyRupee />}
          title="Amount"
          tooltipText="Order Value"
        />
      ),
    },
    {
      dataField: "source",
      text: "Source",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<MdOutlineSource />}
          title="Source"
          tooltipText="Source"
        />
      ),
    },
    {
      dataField: "assignedTo",
      text: "Assigned To",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<FaCalendarAlt />}
          title="Assigned"
          tooltipText="Assigned To"
        />
      ),
    },
    {
      dataField: "status", // Use the field in your data for the status
      text: "Status", // Column header text
      sort: true, // Allow sorting by status
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
        width: "80px", // Adjust width as needed
      },
      style: { textAlign: "center", whiteSpace: "nowrap" }, // Style for column cells
      formatter: (cell) => (
        <span
          style={{ textAlign: "center", padding: "4px", borderRadius: "4px" }}
        >
          {cell || "N/A"} {/* Display the status value or 'N/A' if undefined */}
        </span>
      ),
    },
    {
      dataField: "notes",
      text: "Notes",
      sort: true,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
      },
      style: { textAlign: "center", whiteSpace: "nowrap" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<CgNotes />}
          title="Notes"
          tooltipText="Notes"
        />
      ),
    },
    {
      dataField: "View",
      text: "View",
      sort: false,
      headerStyle: {
        backgroundColor: "#243A73",
        color: "#FFFFFF",
        fontSize: "12px",
        width: "70px",
      },
      style: { whiteSpace: "nowrap", textAlign: "center" },
      headerFormatter: () => (
        <HeaderWithTooltip
          icon={<IoMdEye />}
          title="Actions"
          tooltipText="Actions"
        />
      ),
      formatter: (cell, row) => (
        <div>
          <IoMdEye
            style={{
              color: "linear-gradient(to right, #0B5394, #00619A)",
              cursor: "pointer",
            }}
            onClick={() => handleDelete(row.enquiryID)}
          />
          {/* <BsPencilFill
            style={{ color: "linear-gradient(to right, #0B5394, #00619A)", cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => handleEdit(row.enquiryID)}
          /> */}
        </div>
      ),
      formatter: (cell, row) => (
        <div>
          <BsEyeFill
            style={{ color: "#7C3E66", cursor: "pointer" }}
            onClick={() => handleView(row.enquiryID)} // Add a handler for viewing
          />
        </div>
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: ">>",
    firstPageText: "<<",
    nextPageText: ">",
    prePageText: "<",
    showTotal: true,
    alwaysShowAllBtns: true,
  });

  return (
    <div>
      <Header />
      <div style={{ marginLeft: "-1%", marginTop: "12%", width: "100%" }}>
        <div className="box">
          <div className="App">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={products}
              columns={columns}
              striped
              pagination={pagination}
              selectRow={{
                mode: "radio", // You can also use 'checkbox' if you want multiple rows selectable
                clickToSelect: true, // Allows row selection on click
                selected: selectedRows, // Here, selectedRows is the state variable that tracks selected rows
                onSelect: (row, isSelected) => handleRowSelect(row, isSelected), // Custom function to handle row selection
              }}
            />
          </div>
          <div className="closure-container">
            <Button
              variant="primary"
              onClick={handleCloseModal}
              className="close-enquiry-btn"
            >
              Close Enquiry
            </Button>

            <Modal
              show={showCloseModal}
              onHide={handleCloseModal}
              centered
              className="custom-modal"
            >
              <Modal.Header closeButton className="modal-head">
                <Modal.Title className="modal-title">Close Enquiry</Modal.Title>
              </Modal.Header>

              <Modal.Body className="modal-body">
                <Form>
                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Date</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        type="date"
                        value={new Date().toISOString().split("T")[0]}
                        disabled
                        className="pop-up-control"
                      />
                    </div>
                  </Form.Group>

                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Closer</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        className="pop-up-control"
                      >
                        <option>Won</option>
                        <option>Lost</option>
                      </Form.Control>
                    </div>
                  </Form.Group>

                  <Form.Group className="pop-up">
                    <Form.Label className="pop-up-label">Notes</Form.Label>
                    <div className="pop-up-input">
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter notes here..."
                        value={notes}
                        onChange={handleNotesChange}
                        maxLength={maxCharacters}
                        className="pop-up-control-text"
                      />
                      <div className="character-count">
                        {notes.length}/{maxCharacters} characters
                      </div>
                    </div>
                  </Form.Group>
                </Form>
              </Modal.Body>

              <Modal.Footer className="modal-footer">
                <Button
                  variant="primary"
                  onClick={handleSave}
                  className="save-btn"
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Closure;
