import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import axiosConfig from '../services/axiosConfig';
import Loginimage from '../Images/Loginimage.png';
import { logDOM } from '@testing-library/react';

const Login = () => {

  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();

  const handleLoginSuccess = async (response) => {
    try {
      const token = response.credential; 
      localStorage.setItem('authToken', token);
     
      const resp = await axiosConfig.get(`${apiUrl}/validateUser`);
      localStorage.setItem('loggedInUserId',resp.data.userId);
      
      if(resp.data.message === 'Valid Token'){
        navigate('/dashboard');
      } else if (resp.data.message === 'InValid Token') {
        navigate('/UnauthorizedUser');
      }
    } 
    catch (error) {
      console.error('Error checking user authorization:', error);
    }
  };
  
  

  const handleLoginFailure = (error) => {
    console.error('Google Login Failed:', error);
  };

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        {/* Left side: Logo/Image */}
        <div style={styles.leftContainer}>
        <img 
  src={Loginimage} 
  alt="Login" 
  style={styles.image} 
/>
        </div>

        {/* Right side: Header and Google Login */}
        <div style={styles.rightContainer}>
         
          <p style={styles.subHeader}>Continue with your Google account</p>
          <GoogleOAuthProvider clientId="640763498956-2781d9s23h9572a91qkkranqenohulk5.apps.googleusercontent.com">

            <div style={styles.googleLoginContainer}>
              <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
              />
            </div>
          </GoogleOAuthProvider>
        </div>
      </div>
    </div>
  );
};

// Updated styles for the component
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw', // Full width of the viewport
    backgroundColor: '#f7f7f7',
    padding: '0', // Ensure no padding on the container
    margin: '0',  // Ensure no margin on the container
  },
  box: {
    display: 'flex',
    width: '100%',
    maxWidth: '700px',
    height: '400px', // Increased height
    border: `2px solid #7c3e66`,
    borderRadius: '8px',
    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.1)',
    backgroundColor: '#fff',
    overflow: 'hidden',
  },
  leftContainer: {
    width: '50%', 
    backgroundColor: '#f0f0f5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'left',
    margin: '0',  // Remove any automatic margin
    padding: '0', // Remove any automatic padding
  },
  image: {
    width: '100%',
    height: '450px', 
    objectFit: 'cover',
  },
  rightContainer: {
    width: '40%', // Adjust width for remaining space
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  header: {
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#7C3E66',
    textAlign: 'center',
    marginBottom: '10px',
  },
  subHeader: {
    fontSize: '16px',
    color: '#555',
    textAlign: 'center',
    marginBottom: '20px',
  },
  googleLoginContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  }

};

export default Login;
