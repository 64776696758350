import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import Button from "react-bootstrap/Button";
 import "../../css/Company.css";
import { useNavigate, useLocation } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "react-datepicker/dist/react-datepicker.css";
import { BsTrash3Fill, BsPencilFill } from "react-icons/bs";
import axiosConfig from "../../services/axiosConfig";
import DatePicker from "react-datepicker";

function Customer() {
  const navigate = useNavigate();
  const addNavigateButton = () => {
    navigate("/companyManagement");
  };
  // const [name,setName] = useState('');
  const [companyname, setcompanyname] = useState("");
  const [segment, setsegment] = useState("");
  const [street, setStreet] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [StreetLine, setStreetLine] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [Source, setSource] = useState("");
  const [Category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [Name, setname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Productenquired, setProductenquired] = useState("");
  const [phone, setPhone] = useState('');
  const [Equivalentmodel, setEquivalentmodel] = useState("");
  const [Qty, setQty] = useState("");
  const [Expectedprice, setExpectedprice] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState(
    new Date().toISOString().split("T")[0] // Current date in "yyyy-MM-dd" format
  );
  const [Nextfollowup, setNextfollowup] = useState(null); // Placeholder for next follow-up
  const [checkboxValue1, setCheckboxValue1] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const location = useLocation();
  const { enquiryID } = location.state || {}; // Get enquiryID from the passed state
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [OrderValue, setOrderValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [Description, setDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [enquiryProducts, setEnquiryProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [EnquiryID, setEnquiryID] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [Total, setTotal] = useState("");
  const [Notes, setNotes] = useState("");
  const [assigned, setAssigned] = useState("");

  const [Chalanges, setChalanges] = useState("");
  const [Requirement, setRequirement] = useState("");
  const [stages, setStages] = useState("");
  const [winPossibilities, setWinPossibilities] = useState("");
  const [Competitor, setCompetitor] = useState("");
  const [Priority, setPriority] = useState("");
  const [active, setActive] = useState("Open");
  const [companyId, setCompanyId] = useState("");
  const [companyAddressId, setCompanyAddressId] = useState("");
  const [City, setCity] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [date, setDate] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [assignedOptions, setAssignedOptions] = useState([]);
  const [contacts, setContacts] = useState([
    { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
  ]);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const openModalWithDelay = () => {
    // Show the loader
    setIsLoaderVisible(true);

    // Set a 5-second delay
    setTimeout(() => {
      setIsLoaderVisible(false); // Hide the loader
      setIsModalOpen(true); // Open the modal
    }, 1000);
  };

 
  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://healthcare-dev.erlsoft.com/api/sales/authorizedUsers"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setAssignedOptions(data); // Assuming the API returns an array of objects with `id` and `name` properties
      } catch (error) {
        console.error("Error fetching assigned options:", error);
      }
    };

    fetchData();
  }, []);

  const handleSelect = (option) => {
    setAssigned(option.user_name); // Set the selected user_name
    setDropdownOpen(false); // Close the dropdown
  };

  // State to store form data
  const [formData, setFormData] = useState({
    productName: "",
    productId: "", // Store the selected product ID
    modelNo: "",
    qty: 0,
    orderValue: 0,
    description: "",
  });

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCompanies([]);
    } else {
      const filtered = companies.filter((company) =>
        company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  }, [searchTerm, companies]);

  // Handle company selection
  const handleCompanySelect = (company) => {
    setSearchTerm(company.company_name); // Set the search term to the selected company
    setFilteredCompanies([]); // Clear suggestions
    handleChangetypes({ target: { value: company.company_name } }); // Load the company details
  };

  const handleChangeUser = (event) => {
    const selectedValue = event.target.value;
    setUser(selectedValue);

    // If "New" is selected, reset all fields
    if (selectedValue === "Ford") {
      setSearchTerm("");
      setSource("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setCity("");
      setDistrict("");
      setState("");
      setPincode("");
      setContacts([
        { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
      ]); // Reset contacts array
    }
  };
  // Hide suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#companySearch")) {
        setFilteredCompanies([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Open modal
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosConfig.get("/products");
      const data = response.data; // Axios automatically parses the JSON response
      setProducts(data); // Assuming the API returns an array of products
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleProductChange = (product) => {
    setFormData({
      ...formData,
      productName: product.product_name, // Set product name
      productId: product.product_id, // Set product ID
    });
    setIsDropdownOpen(false); // Close the dropdown once a product is selected
  };

  const handleDropdownClick = () => {
    if (!isDropdownOpen) {
      fetchProducts(); // Fetch products when dropdown is opened
    }
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };

  // Close modal
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };

  // Handle form submission (Add button)
  const handleClose = () => {
    console.log("Form submitted with data:", formData);
    closeModal(); // Close the modal after submission
  };
  // const [companyId, setCompanyId] = useState(0);

  const handleDateChange = (date) => {
    setNextfollowup(date);
  };
  // const [checkboxValue1, setCheckboxValue1] = useState(false);
  // const [checkboxValue2, setCheckboxValue2] = useState(false);
  // const [inputdata, setInputdata] = useState({
  //   quantity:"",
  // })

  const handleDelete = (enquiryID) => {
    // Your delete functionality here
    console.log(`Delete enquiry with ID: ${enquiryID}`);
  };

  const handleEdit = (enquiryID) => {
    // Your edit functionality here
    console.log(`Edit enquiry with ID: ${enquiryID}`);
  };
  const [createFileSave, setcreatefileSave] = useState(false);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function company() {
    let companyFile = {
      company_name: companyname,
      segment: segment,
      territory: AreaTerritory,
      company_location: "abc",
      category: type,
      type: user,
    };
    console.log("companyFile:", companyFile);
    // await axiosConfig.post("/add/companies", companyFile).then((response) => {
    //   console.log("response.dataCompany::", response.data.company_id);
    //   contact(response.data.company_id);
    //   address(response.data.company_id);
    //   // setCompanyId(response.data.company_id);
    // });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
    ]);
  };

  const cancelLastContact = () => {
    if (contacts.length > 1) {
      setContacts(contacts.slice(0, -1));
    }
  };

  async function enquiry(companyId, companyAdressId) {
    let enquiryFile = {
      company_id: companyId,
      company_address_id: companyAdressId,
      customer_name: Name,
      email: EmailID, // Populate with state value
      contact: ContactNumber, // Populate with state value
      source: Source,
      total: Total,
      notes: Notes,
      assigned_to: assigned,
      date: NextFollowupAction,
      chalanges: Chalanges,
      requirement_type: Requirement,
      stages: stages,
      win_possibilities: winPossibilities,
      competitor: Competitor,
      priority: Priority,
      status: active,
      products: enquiryProducts, // Ensure this is correctly populated
    };

    console.log("enquiryFile:", enquiryFile);

    try {
      const response = await axiosConfig.post(`/add/enquiry`, enquiryFile);

      if (response.data.enquiry_id) {
        let data_enquiry_id = response.data.enquiry_id;
        localStorage.setItem("enquiryId", data_enquiry_id);
        console.log("Enquiry successfully posted:", response.data);
      }
    } catch (error) {
      console.error("Error posting enquiry:", error);
    }
  }

  let enquiryId = localStorage.getItem("enquiryId");

  useEffect(() => {
    // Check if the action is 'edit' in localStorage
    const action = localStorage.getItem("action");
    if (action === "edit") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    // axiosConfig
    //   .get("/enquirie-product")
    //   .then((response) => {
    //     console.log("Enquiry Products fetched successfully:", response.data);
    //     setEnquiryProducts(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(
    //       "Error fetching enquiry products:",
    //       error.response || error.message
    //     );
    //   });

    const fetchCompanies = async () => {
      try {
        const response = await axiosConfig.get("/companies");
        const sortedCompanies = response.data.sort((a, b) =>
          a.company_name.localeCompare(b.company_name)
        ); // Sort alphabetically
        setCompanies(sortedCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  // Function to handle deleting an enquiry product
  const handleProductDelete = (enquiryProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      axiosConfig
        .delete(`/enquirie-product/${enquiryProductId}`)
        .then((response) => {
          console.log("Product deleted successfully:", response.data);
          // Optionally, you can remove the deleted product from the state
          setEnquiryProducts((prevProducts) =>
            prevProducts.filter(
              (product) => product.enquiry_product_id !== enquiryProductId
            )
          );
        })
        .catch((error) => {
          console.error(
            "Error deleting product:",
            error.response || error.message
          );
        });
    }
  };

  const handleProductEdit = (productId) => {
    const productData = enquiryProducts.find(
      (product) => product.enquiry_product_id === productId
    );

    if (productData) {
      setFormData({
        product: productData.product_name,
        productId: productData.product_id, // Include product_id for editing
        modelNo: productData.model_no,
        qty: productData.quantity,
        orderValue: productData.order_value,
        description: productData.description,
      });
      setSelectedProduct(productId); // Set the selected product ID
      setIsEditing(true); // Enable edit mode
      setIsModalOpen(true); // Open modal
    }
  };

  async function contact(companyId) {
    let concatFile = {
      company_id: companyId,
      name: Name,
      designation: Designation,
      email: EmailID,
      phone: ContactNumber,
      department: Department,
    };
    console.log("concatFile:", concatFile);
    // await axiosConfig.post(`/add/companies_contact`, concatFile);
  }

  const saveEnquiry = async () => {
    if (isEditMode) {
      console.log("Update enquiry");

      try {
        console.log("Nextfollowup:", Nextfollowup);
        const formattedDate = Nextfollowup
          ? Nextfollowup.toISOString().split("T")[0]
          : "";

        // Log the formatted date to ensure it's correct
        console.log("Formatted Date:", formattedDate);

        // Create the body for the PUT request
        const requestBody = {
          customer_name: Name,
          email: EmailID,
          contact: ContactNumber,
          product_enquired: Productenquired,
          status: active,
          total: Total, // Add the Total field
          notes: Notes, // Add the Notes field
          assigned_to: assigned, // Add the Assigned field
          date: NextFollowupAction, // Add the NextFollowupAction field
          chalanges: Chalanges,
          requirement_type: Requirement,
          stages: stages,
          win_possibilities: winPossibilities,
          competitor: Competitor,
          priority: Priority,
          next_follow_up: formattedDate,
          products: enquiryProducts,
        };

        console.log("Request Body:", requestBody);

        // Construct the URL without query parameters
        const url = `/update/${enquiryID}`;

        // Perform the PUT request using axiosConfig
        const response = await axiosConfig.put(url, requestBody);

        // Handle the successful update
        console.log("Update response:", response.data);
        // alert("Enquiry updated successfully");
      } catch (error) {
        // Handle any errors during the update
        console.error("Error updating enquiry:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
        alert("Failed to update enquiry. Please try again.");
      }
    } else {
      console.log("Create enquiry");

      // Handle add logic here (if this is for creating an enquiry)
      // You may call other functions for creating an enquiry if needed
      await company(); // Example function, you can replace it with actual logic
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const getCompanyDetail = localStorage.getItem("companyDetails");
  // console.log("rajjjjj ", getCompanyDetail);
  const parsedCompanyDetail = JSON.parse(getCompanyDetail);
  // console.log("parsedCompanyDetail::", JSON.parse(getCompanyDetail));

  const handleChangetypes = async (event) => {
    setcompanyname(event.target.value);
    const companyName = event.target.value;
    console.log("handleChangetypesSelectedValue:", companyName);

    try {
      const response = await axiosConfig.get(
        `/companies/details/${companyName}`
      );
      const companyDetails = response.data;

      console.log("companyDetails:", companyDetails);

      if (companyDetails) {
        // Set the company ID and company address ID
        setCompanyId(companyDetails.company_id || "");
        setCompanyAddressId(companyDetails.address?.company_address_id || ""); // Adjust property names if needed

        // Populate contact and other relevant details
        setname(companyDetails.contact?.name || "");
        setEmailID(companyDetails.contact?.email || "");
        setContactNumber(companyDetails.contact?.phone || "");
        setSource(companyDetails.source || ""); // Adjust the key if different in response
        setCity(companyDetails.address?.city || "");

        // Populate the state with the fetched company details (if needed)
        setDoorNo(companyDetails.address?.door_no || "");
        setstreetname(companyDetails.address?.street_name || "");
        setStreetLine(companyDetails.address?.street_line_2 || "");
        setDistrict(companyDetails.address?.district || "");
        setAreaTerritory(companyDetails.territory || "");
        setState(companyDetails.address?.state || "");
        setPincode(companyDetails.address?.postal_code || "");
        setsegment(companyDetails.segment || "");

        // Populate contact details
        const newContact = {
          Name: companyDetails.contact?.name || "",
          Designation: companyDetails.contact?.designation || "",
          EmailID: companyDetails.contact?.email || "",
          ContactNumber: companyDetails.contact?.phone || "",
        };

        // Update the state for contacts if needed
        setContacts([newContact]);
      } else {
        // Optionally clear input fields if no data is found
        // clearAllInputs();
      }
    } catch (error) {
      console.error("Error fetching company details: ", error);
      // Optionally clear input fields on error
      // clearAllInputs();
    }
  };

  const handleAddProduct = () => {
    const updatedProduct = {
      product_name: formData.productName, // Updated product name
      product_id: formData.productId, // Ensure product_id is updated
      model_no: formData.modelNo,
      quantity: formData.qty,
      order_value: formData.orderValue,
      description: formData.description,
      // enquiry_product_id: selectedProduct || new Date().getTime(), // Unique ID for new product
    };

    if (isEditing) {
      // Update existing product in the array
      setEnquiryProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.enquiry_product_id === selectedProduct
            ? updatedProduct
            : product
        )
      );
    } else {
      // Add new product to the array
      setEnquiryProducts((prevProducts) => [...prevProducts, updatedProduct]);
    }

    // Clear form and reset state
    setFormData({
      product: "",
      productId: "", // Clear product ID field
      modelNo: "",
      qty: 0,
      orderValue: 0,
      description: "",
    });
    setIsEditing(false);
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    console.log("useEffect called.");

    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      // Fetch enquiry details
      axiosConfig
        .get(`/enquiries/details/${enquiryID}`)
        .then((response) => {
          const data = response.data;
          setEnquiryDetails(data); // Store the response data if needed
          setEnquiryProducts(data.products || []);

          // Populate contacts from the response
          if (data) {
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setUser(data.customer_name || "");
            setEmailID(data.email || "");
            setContactNumber(data.contact || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");

            // Address details
            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_name || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setSource(address.source || "");
            }

            // Contact details
            const contact = data.masterCompany?.contact;
            if (contact) {
              setContacts([
                {
                  // Ensure contacts is an array
                  Name: contact.name || "",
                  Designation: contact.designation || "",
                  EmailID: contact.email || "",
                  ContactNumber: contact.phone || "",
                  Department: contact.department || "",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching enquiry details:", error);
        });
    }
  }, [enquiryID]);

  return (
    <div>
    <Header />
    <form>
      <h2 className="company-info-title">Create New Customer</h2>
  
      <div className="company-info-container">
        {/* Row 1 */}
        <div className="company-info-row">
          <label className="company-info-label">
            Company Name<span className="required">*</span>
          </label>
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search company name"
            className="company-info-input"
          />
          {filteredCompanies.length > 0 && (
            <ul className="company-info-dropdown-list">
              {filteredCompanies.map((company) => (
                <li
                  key={company.company_id}
                  onClick={() => handleCompanySelect(company)}
                  company                >
                  {company.company_name}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="company-info-row">
          <label className="company-info-label">Category</label>
          <select
            value={user}
            onChange={handleChangeUser}
            className="company-info-input"
          >
            <option value="Existing">Existing</option>
            <option value="New">New</option>
          </select>
        </div>
        <div className="company-info-row">
          <label className="company-info-label">Source</label>
          <select
            value={Source}
            onChange={(e) => setSource(e.target.value)}
            className="company-info-input"
          >
            <option value="Walk">Walk-in</option>
            <option value="Mail">Mail</option>
            <option value="Expo">Expo</option>
            <option value="Web">Website</option>
          </select>
        </div>
  
        {/* Row 2 */}
        <div className="company-info-row">
          <label className="company-info-label">
            Door No<span className="required">*</span>
          </label>
          <input
            type="text"
            value={DoorNo}
            onChange={(e) => setDoorNo(e.target.value)}
            required
            className="company-info-input"
          />
        </div>
        <div className="company-info-row">
          <label className="company-info-label">Street Line-1</label>
          <input
            type="text"
            value={streetname}
            onChange={(e) => setstreetname(e.target.value)}
            className="basic-info-input"
          />
        </div>
        <div className="company-info-row">
          <label className="company-info-label">Street Line-2</label>
          <input
            type="text"
            value={StreetLine}
            onChange={(e) => setStreetLine(e.target.value)}
            className="company-info-input"
          />
        </div>
  
        {/* Row 3 */}
        <div className="company-info-row">
          <label className="company-info-label">City</label>
          <input
            type="text"
            value={City}
            onChange={(e) => setCity(e.target.value)}
            className="company-info-input"
          />
        </div>
        <div className="company-info-row">
          <label className="company-info-label">District</label>
          <input
            type="text"
            value={District}
            onChange={(e) => setDistrict(e.target.value)}
            className="company-info-input"
          />
        </div>
        <div className="company-info-row">
          <label className="company-info-label">State</label>
          <input
            type="text"
            value={state}
            onChange={(e) => setState(e.target.value)}
            className="company-info-input"
          />
        </div>
  
       
  
        {/* Row 4 - Pincode */}
        <div className="company-info-row">
          <label className="company-info-label">Pincode</label>
          <input
            type="text"
            value={pincode}
            onChange={(e) => setPincode(e.target.value)}
            className="company-info-input"
          />
        </div>
      
       {/* Row 4 - Phone */}
       <div className="company-info-row">
          <label className="company-info-label">Phone</label>
          <input
            type="text"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            className="company-info-input"
          />
        </div>
        </div>
    </form>

  
    <div>
  {contacts.map((contact, index) => (
    <form key={index}>
      <h2 className="company-info-title">
        Customer Contact Person Details {index + 1}
      </h2>

      <div className="company-info-container">
        {/* Row 1: Name */}
        <div className="company-info-row">
          <label className="company-info-label">Name</label>
          <input
            type="text"
            value={contact.Name}
            onChange={(e) => handleContactChange(index, "Name", e.target.value)}
            className="company-info-input"
          />
        </div>

        {/* Row 2: Designation */}
        <div className="company-info-row">
          <label className="company-info-label">Designation</label>
          <input
            type="text"
            value={contact.Designation}
            onChange={(e) =>
              handleContactChange(index, "Designation", e.target.value)
            }
            className="company-info-input"
          />
        </div>

        {/* Row 3: Email ID */}
        <div className="company-info-row">
          <label className="company-info-label">Email ID</label>
          <input
            type="text"
            value={contact.EmailID}
            onChange={(e) =>
              handleContactChange(index, "EmailID", e.target.value)
            }
            className="company-info-input"
          />
        </div>

        {/* Row 4: Contact Number */}
        <div className="company-info-row">
          <label className="company-info-label">Contact Number</label>
          <input
            type="text"
            value={contact.ContactNumber}
            onChange={(e) =>
              handleContactChange(index, "ContactNumber", e.target.value)
            }
            className="company-info-input"
          />
        </div>

        {/* Add Another Contact Button */}
        <div className="right-align-button" >
          <button
            type="button"
            className="btn btn-primary"
            style={{
              fontFamily: "'Manrope'",
              fontSize: "14px",
              padding: "5px 10px",
              height: "30px",
              background: "linear-gradient(to right, #0B5394, #00619A)",
              border: "1px solid #7C3E66",
              marginLeft:"50px"
            }}
            onClick={addContact}
          >
            Add Another Contact
          </button>
        </div>
      </div>
    </form>
  ))}




<Button
        variant="primary"
        style={{
          marginLeft: "500px",
          marginTop: "35px",
          marginBottom:"10px",
          fontFamily: "'Manrope'",
          fontSize: "14px",
          background: "linear-gradient(to right, #0B5394, #00619A)",
          // border: "1px solid #7C3E66",
        }}
        onClick={() => {
          setcreatefileSave(true);
          // Call saveEnquiry if it's in edit mode, otherwise call enquiry for new entry
          if (isEditMode) {
            saveEnquiry(); // Call update logic for edit mode
          } else {
            enquiry(companyId, companyAddressId); // Call create logic for new enquiry
          }
        }}
      >
        {isEditMode ? "Update" : "Submit"}
      </Button>

      <Dialog
        open={createFileSave}
        onClose={() => setcreatefileSave(false)}
        PaperProps={{
          sx: {
            width: "230px",
            height: "180px",
            position: "absolute",
            left: "700px",
            top: "150px",
            border: "1px solid #F8F7FF",
            borderRadius: "15px",
            background: "#FFFFFF",
          },
        }}
      >
        <DialogContent
          dividers
          style={{
            fontFamily: "'Poppins'",
            fontWeight: "600",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
         Company ID:{enquiryId} Submited Successfully...!!!
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Button
            onClick={addNavigateButton}
            variant="contained"
            autoFocus
            style={{
              height: "38px",
              width: "71px",
              background: "yellow",
              fontWeight: "400",
              fontFamily: "Manrope",
              border: "1px solid #000000",
              borderRadius: "5px",
              textTransform: "capitalize",
            }}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        variant="primary"
        style={{
          marginLeft: "20px",
          marginTop: "35px",
          marginBottom: "10px",
          fontFamily: "'Manrope'",
          fontSize: "14px",
          color: "black",
          background: "#fff",
          // border: "1px solid #7C3E66",
        }}
        onClick={() => {
          // clearAllInputs();
        }}
      >
        Reset
      </Button>
      {/* </form>
          </div>
          <div></div>
        </div> */}
    </div>
         

                       
               
    
   

        {/* Add and Cancel buttons */}
        {/* <div className="d-flex justify-content-end mt-3"> */}
          {/* {contacts.length > 1 && (
              <button
                type="button"
                className="btn btn-danger me-2"
                onClick={cancelLastContact}
              >
                Cancel
              </button>
            )} */}
          {/* <button
              type="button"
              className="btn btn-primary"
              onClick={addContact}
              variant="primary"
              style={{
                marginRight: "10px",
                marginTop: "-60px",
                fontFamily: "'Manrope'",
                fontSize: "14px",
                padding: "5px 10px", // Reduced padding
                height: "30px", // Optional: set a fixed height
                background: "#7C3E66",
                border: "1px solid #7C3E66",
                marginBottom:"0px",
              }}
            >
              Add Another Contact
            </button> */}
        </div>
   
  );
}
export default Customer;
