import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsPlusCircleFill, BsHash, BsChevronDown } from 'react-icons/bs';
import { FaTag, FaBarcode } from 'react-icons/fa';
import axios from 'axios';
import '../../css/Header.css';
import '../../css/CustomerManagement.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useNavigate } from 'react-router';
import Header from '../../home/Header';

const HeaderWithTooltip = ({ icon, title, tooltipText }) => (
  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id={`tooltip-${title}`}>{tooltipText}</Tooltip>}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {icon}
        <span style={{ marginLeft: 5, color: '#FFFFFF' }}>{title}</span>
      </div>
    </OverlayTrigger>
    <BsChevronDown style={{ marginLeft: '5px', color: '#FFFFFF' }} />
  </div>
);

function ProductManagement() {
  const [products, setProducts] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate('/Product');
  };
  const [formData, setFormData] = useState({
    productName: '',
    partNo: '',
    hsnNo: '',
  });

  // Fetch Products
  useEffect(() => {
    // Replace this with your actual API URL
    const fetchProducts = async () => {
      try {
        const response = await axios.get('/api/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      }
    };

    fetchProducts();
  }, []);

  // Handle Input Change in Modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Submit Form
  const handleFormSubmit = (e) => {
    e.preventDefault();
    setProducts([...products, { ...formData, id: products.length + 1 }]);
    setShowModal(false);
  };

  // Table Columns
  const columns = [
    {
      dataField: 'id',
      text: 'S. No.',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '80px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<BsHash />} title="S. No." tooltipText="Serial Number" />
      ),
    },
    {
      dataField: 'productName',
      text: 'Product Name',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '200px' },
      style: { textAlign: 'left', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaTag />} title="Product Name" tooltipText="Name of the Product" />
      ),
    },
    {
      dataField: 'type',
      text: 'Type (If any)',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '150px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaTag />} title="Type" tooltipText="Product Type (If applicable)" />
      ),
    },
    {
      dataField: 'partNo',
      text: 'Part No. (If any)',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '150px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaBarcode />} title="Part No." tooltipText="Part Number (If applicable)" />
      ),
    },
    {
      dataField: 'hsnNo',
      text: 'HSN No. (If any)',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '150px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaBarcode />} title="HSN No." tooltipText="HSN Code (If applicable)" />
      ),
    },
    {
      dataField: 'unit',
      text: 'Unit (If any)',
      sort: true,
      headerStyle: { backgroundColor: '#243A73', color: '#FFFFFF', fontSize: '12px', width: '150px' },
      style: { textAlign: 'center', whiteSpace: 'nowrap' },
      headerFormatter: () => (
        <HeaderWithTooltip icon={<FaTag />} title="Unit" tooltipText="Product Unit (If applicable)" />
      ),
    },
  ];

  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 5,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
     // Optionally, you can add a custom class here
     paginationClassName: 'custom-pagination-container',
  });
  return (
    <div>
    <Header />
    <div style={{ marginLeft: '-1%', marginTop: '10%', width: '100%' }}>
      <div className="box">
        <Button
          variant="primary"
          style={{
            marginLeft: '1015px',
            background: 'linear-gradient(to right, #0B5394, #00619A)',
            marginTop: '-230px',
            width: '200px',
          }}
          onClick={handleNavigate}
        >
          <BsPlusCircleFill style={{ marginRight: '8px' }} />
          Add New Product
        </Button>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={products}
          columns={columns}
          pagination={pagination}
        />
      </div>
    </div>

    </div>
   

    
  );
}

export default ProductManagement;
