import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { BsInfoCircleFill, BsQuestionCircleFill, BsChatQuoteFill, BsFillCartCheckFill, BsPersonFill, BsFileEarmarkMedicalFill,BsBagFill } from 'react-icons/bs';
import { FiCreditCard, FiSettings } from 'react-icons/fi';
import { BiSolidBadgeDollar } from 'react-icons/bi';
import { VscExport } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import avatar from '../Images/Profile.png';
import logo from '../Images/Erllogo.png';
import "bootstrap/dist/css/bootstrap.min.css";
import backgroundImage from '../Images/Sidebarbackgroundimage.png';
import { Outlet } from 'react-router';
import React, { useState } from 'react';

function SideMenu() {
  const [collapsed, setCollapsed] = useState(false);
  // const [loading, setLoading] = useState(false);

  // // Handle loading when navigating
  // const delayNavigation = (delay) => {
  //   setLoading(true);  // Show loading spinner
  //   setTimeout(() => {
  //     setLoading(false);  // Hide loading spinner after delay
  //   }, delay);
  // };

  // Get user info from localStorage
  let user_mail = localStorage.getItem('userEmail');
  let user_firstName = localStorage.getItem('user_firstName');
  let user_lastName = localStorage.getItem('user_lastName');
  
  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div style={{ height: '100vh', display: 'flex', overflow: 'hidden' }}>
      {/* Sidebar */}
      <Sidebar
        collapsed={collapsed}
        style={{
          height: '100vh', // Ensure the sidebar fits the viewport height
          textAlign: 'left',
          width: collapsed ? '80px' : '260px',
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 1,
          color: 'white',
          overflow: 'hidden', // Remove the scrollbar
          transition: 'width 0.3s ease',
        }}
      >
        {/* Background Image */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundAttachment: 'fixed',
            zIndex: 0,
          }}
        ></div>

        {/* Overlay for Contrast */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Black overlay for text contrast
            zIndex: 1,
          }}
        ></div>

        {/* Content */}
        <div style={{ position: 'relative', zIndex: 2 }}>

        <button
            onClick={toggleSidebar}
            style={{
              background: 'transparent',
              color: 'white',
              border: 'none',
              cursor: 'pointer',
              margin: '10px',
              fontSize: '20px',
              textAlign: 'center',
            }}
          >
            {collapsed ? '☰' : '☰'}
          </button>

          <Menu
            menuItemStyles={{
              button: {
                '&:hover': {
                  backgroundColor: "#0B5394",
                  color: '#1b4332',
                  height: '50px',
                  borderRadius: '50px',
                },
              },
            }}
          >
            {/* Logo Section */}
            {!collapsed && (
              <Menu style={{ textAlign: 'center', color: 'white' }}>
                <img
                  src={logo}
                  alt="Logo"
                  style={{
                    width: '100px',
                    height: '75px',
                    margin: '0 auto',
                    marginTop:"-50px"
                  }}
                />
              </Menu>
            )}

            {/* Avatar and Username */}
            {!collapsed && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "15px",
                  color: "white",
                  fontFamily: '"Poppins", sans-serif',
                  fontSize: "14px",
                  marginBottom: "15px",
                  borderRadius: "15px",
                  maxWidth: "90%",
                  overflow: "hidden",
                }}
              >
             <div style={{ marginRight: "15px" }}>
                  <img
                    alt="User"
                    src={avatar}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      objectFit: "cover",
                      border: "2px solid white",
                      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.3)",
                      marginTop:"-10px"
                    }}
                  />
                </div>

                <div style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  <div
                    style={{
                      fontWeight: "500",
                      color: "#f0f0f0",
                      fontSize: "13px",
                      opacity: "0.8",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {user_firstName} {user_lastName}
                  </div>
                  <div
                    style={{
                      fontSize: "12px",
                      color: "#f0f0f0",
                      opacity: "0.8",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Administrator
                  </div>
                </div>
              </div>
            )}

            {/* Menu Items */}
            <MenuItem style={{marginTop:"-20px",color:"white"}}
              component={<Link to="/CompanyManagement" />}
              icon={<BsInfoCircleFill style={{ fontSize: '21px', color: 'white', }} />}
            >
              {!collapsed && "Company Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/ProductManagement" />}
              icon={<BsBagFill style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Product Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/enquiryManagement" />}
              icon={<BsQuestionCircleFill style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Enquiry Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/followupManagement" />}
              icon={<VscExport style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Follow Up"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/quotation" />}
              icon={<BsChatQuoteFill style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Quotation Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/order" />}
              icon={<BsFillCartCheckFill style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Order Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/pay" />}
              icon={<FiCreditCard style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Payment Status Tracking"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/closure" />}
              icon={<BiSolidBadgeDollar style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Closure Management"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/user" />}
              icon={<BsPersonFill style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Manage User"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/dash" />}
              icon={<BsFileEarmarkMedicalFill style={{ fontSize: '25px', color: 'white' }} />}
            >
              {!collapsed && "Reports"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/board" />}
              icon={<FiSettings style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Settings"}
            </MenuItem>
            <MenuItem style={{marginTop:"-7px",color:"white"}}
              component={<Link to="/login" />}
              icon={<VscExport style={{ fontSize: '21px', color: 'white' }} />}
            >
              {!collapsed && "Logout"}
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
    </div>
  );
}
export default SideMenu;