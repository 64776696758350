import React, { useState, useEffect } from "react";
import Header from "../../home/Header";
import Button from "react-bootstrap/Button";
import "../../css/Follow.css";
import { useNavigate, useLocation } from "react-router";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import "react-datepicker/dist/react-datepicker.css";
import { BsTrash3Fill, BsPencilFill } from "react-icons/bs";
import axiosConfig from "../../services/axiosConfig";

function FollowUp() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const addNavigateButton = () => {
    navigate("/followupManagement");
  };
  // const [name,setName] = useState('');
  const [companyname, setcompanyname] = useState("");
  const [segment, setsegment] = useState("");
  const [street, setStreet] = useState("");
  const [type, setType] = useState("");
  const [user, setUser] = useState("");
  const [DoorNo, setDoorNo] = useState("");
  const [streetname, setstreetname] = useState("");
  const [StreetLine, setStreetLine] = useState("");
  const [District, setDistrict] = useState("");
  const [AreaTerritory, setAreaTerritory] = useState("");
  const [Source, setSource] = useState("");
  const [Category, setCategory] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [Name, setname] = useState("");
  const [Designation, setDesignation] = useState("");
  const [Department, setDepartment] = useState("");
  const [Productenquired, setProductenquired] = useState("");
  const [Equivalentmodel, setEquivalentmodel] = useState("");
  const [Qty, setQty] = useState("");
  const [Expectedprice, setExpectedprice] = useState("");
  const [Nextfollowup, setNextfollowup] = useState(null);
  const [checkboxValue1, setCheckboxValue1] = useState(false);
  const [checkboxValue2, setCheckboxValue2] = useState(false);
  const location = useLocation();
  const { enquiryID } = location.state || {}; // Get enquiryID from the passed state
  const [enquiryDetails, setEnquiryDetails] = useState(null);
  const [OrderValue, setOrderValue] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [Description, setDescription] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [enquiryProducts, setEnquiryProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [EnquiryID, setEnquiryID] = useState("");
  const [EmailID, setEmailID] = useState("");
  const [ContactNumber, setContactNumber] = useState("");
  const [Total, setTotal] = useState("");
  const [Notes, setNotes] = useState("");
  const [FollowNotes, setFollowNotes] = useState("");
  const [Assigned, setAssigned] = useState("");
  const [NextFollowupAction, setNextFollowupAction] = useState(
    new Date().toISOString().split("T")[0]
  ); // Current date
  const [Chalanges, setChalanges] = useState("");
  const [Requirement, setRequirement] = useState("");
  const [stages, setStages] = useState("");
  const [winPossibilities, setWinPossibilities] = useState("");
  const [Competitor, setCompetitor] = useState("");
  const [Priority, setPriority] = useState("");
  const [active, setActive] = useState("Open");
  const [companyId, setCompanyId] = useState("");
  const [companyAddressId, setCompanyAddressId] = useState("");
  const [City, setCity] = useState("");
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredCompanies, setFilteredCompanies] = React.useState([]);
  const [contacts, setContacts] = useState([
    { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
  ]);

  const [Challenges, setChallenges] = useState("");
  const [Budget, setBudget] = useState("");
  const [Deadline, setDeadline] = useState("");
  const [Contactperson1, setContactperson1] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Feedback, setFeedback] = useState("");
  const [followUps, setFollowUps] = useState([]);
  const [formData, setFormData] = useState({
    productName: "",
    productId: "", // Store the selected product ID
    modelNo: "",
    qty: 0,
    orderValue: 0,
    description: "",
    contactType: "", // added field
    escalate: false, // added checkbox field
    requirementType: "",
    stages: "",
    priority: "",
    competitor: "",
    winPossibilities: "",
    challenges: "",
    contactType: '',
    followUpStage: '',
    nextFollowupDate: '',
    escalate: false,
    FollowNotes: '',
    createdDate: new Date().toISOString().split('T')[0], 
  });
 

  // Handle form data changes for all fields

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setFilteredCompanies([]);
    } else {
      const filtered = companies.filter((company) =>
        company.company_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredCompanies(filtered);
    }
  }, [searchTerm, companies]);

  // Handle company selection
  const handleCompanySelect = (company) => {
    setSearchTerm(company.company_name); // Set the search term to the selected company
    setFilteredCompanies([]); // Clear suggestions
    handleChangetypes({ target: { value: company.company_name } }); // Load the company details
  };

  const handleChangeUser = (event) => {
    const selectedValue = event.target.value;
    setUser(selectedValue);

    // If "New" is selected, reset all fields
    if (selectedValue === "Ford") {
      setSearchTerm("");
      setSource("");
      setDoorNo("");
      setstreetname("");
      setStreetLine("");
      setCity("");
      setDistrict("");
      setState("");
      setPincode("");
      setContacts([
        { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
      ]); // Reset contacts array
    }
  };
  // Hide suggestions when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest("#companySearch")) {
        setFilteredCompanies([]);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Open modal
  const openModal = () => {
    console.log("Modal is opening...");
    setIsModalOpen(true); // This will trigger the modal to appear
  };

  const fetchProducts = async () => {
    try {
      const response = await axiosConfig.get("/products");
      const data = response.data; // Axios automatically parses the JSON response
      setProducts(data); // Assuming the API returns an array of products
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  useEffect(() => {
    console.log("useEffect called.");

    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      // Fetch enquiry details
      axiosConfig
        .get(`/enquiries/details/${enquiryID}`)
        .then((response) => {
          const data = response.data;
          setEnquiryDetails(data); // Store the response data if needed
          setEnquiryProducts(data.products || []);

          // Populate contacts from the response
          if (data) {
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setUser(data.customer_name || "");
            setEmailID(data.masterCompany.contact.email || "");
            setContactNumber(data.masterCompany.contact.phone || "");
            setname(data.masterCompany.contact.name || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");

            // Address details
            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_name || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setSource(address.source || "");
            }

            // Contact details
            const contact = data.masterCompany?.contact;
            if (contact) {
              setContacts([
                {
                  // Ensure contacts is an array
                  Name: contact.name || "",
                  Designation: contact.designation || "",
                  EmailID: contact.email || "",
                  ContactNumber: contact.phone || "",
                  Department: contact.department || "",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching enquiry details:", error);
        });
    }
  }, [enquiryID]);

  const handleProductChange = (product) => {
    setFormData({
      ...formData,
      productName: product.product_name, // Set product name
      productId: product.product_id, // Set product ID
    });
    setIsDropdownOpen(false); // Close the dropdown once a product is selected
  };

  const handleDropdownClick = () => {
    if (!isDropdownOpen) {
      fetchProducts(); // Fetch products when dropdown is opened
    }
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown state
  };

  // Close modal
  const closeModal = () => {
    console.log("Modal is closing...");
    setIsModalOpen(false); // This will close the modal
  };

  const handleOrderDateChange = (date) => {
    setNextFollowupAction(date);
  };

  // Handle form submission (Add button)
  const handleClose = () => {
    console.log("Form submitted with data:", formData);
    closeModal(); // Close the modal after submission
  };
  // const [companyId, setCompanyId] = useState(0);

  const handleDateChange = (date) => {
    setNextfollowup(date);
  };
  // const [checkboxValue1, setCheckboxValue1] = useState(false);
  // const [checkboxValue2, setCheckboxValue2] = useState(false);
  // const [inputdata, setInputdata] = useState({
  //   quantity:"",
  // })

  const handleDelete = (enquiryID) => {
    // Your delete functionality here
    console.log(`Delete enquiry with ID: ${enquiryID}`);
  };

  const handleEdit = (enquiryID) => {
    // Your edit functionality here
    console.log(`Edit enquiry with ID: ${enquiryID}`);
  };
  const [createFileSave, setcreatefileSave] = useState(false);
  const handleChange = (event) => {
    setType(event.target.value);
  };

  async function company() {
    let companyFile = {
      company_name: companyname,
      segment: segment,
      territory: AreaTerritory,
      company_location: "abc",
      category: type,
      type: user,
    };
    console.log("companyFile:", companyFile);
    // await axiosConfig.post("/add/companies", companyFile).then((response) => {
    //   console.log("response.dataCompany::", response.data.company_id);
    //   contact(response.data.company_id);
    //   address(response.data.company_id);
    //   // setCompanyId(response.data.company_id);
    // });
  }

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value, // handle checkbox specifically
    }));
  };

  const addContact = () => {
    setContacts([
      ...contacts,
      { Name: "", Designation: "", EmailID: "", ContactNumber: "" },
    ]);
  };

  const cancelLastContact = () => {
    if (contacts.length > 1) {
      setContacts(contacts.slice(0, -1));
    }
  };

  async function enquiry(companyId, companyAdressId) {
    let enquiryFile = {
      company_id: companyId,
      company_address_id: companyAdressId,
      customer_name: Name,
      email: EmailID, // Populate with state value
      contact: ContactNumber, // Populate with state value
      source: Source,
      total: Total,
      notes: Notes,
      assigned_to: Assigned,
      date: NextFollowupAction,
      chalanges: Chalanges,
      requirement_type: Requirement,
      stages: stages,
      win_possibilities: winPossibilities,
      competitor: Competitor,
      priority: Priority,
      status: active,
      products: enquiryProducts, // Ensure this is correctly populated
    };

    console.log("enquiryFile:", enquiryFile);

    try {
      const response = await axiosConfig.post(`/add/enquiry`, enquiryFile);

      if (response.data.enquiry_id) {
        let data_enquiry_id = response.data.enquiry_id;
        localStorage.setItem("enquiryId", data_enquiry_id);
        console.log("Enquiry successfully posted:", response.data);
      }
    } catch (error) {
      console.error("Error posting enquiry:", error);
    }
  }

  let enquiryId = localStorage.getItem("enquiryId");

  useEffect(() => {
    // Check if the action is 'edit' in localStorage
    const action = localStorage.getItem("action");
    if (action === "edit") {
      setIsEditMode(true);
    } else {
      setIsEditMode(false);
    }
    // axiosConfig
    //   .get("/enquirie-product")
    //   .then((response) => {
    //     console.log("Enquiry Products fetched successfully:", response.data);
    //     setEnquiryProducts(response.data);
    //   })
    //   .catch((error) => {
    //     console.error(
    //       "Error fetching enquiry products:",
    //       error.response || error.message
    //     );
    //   });

    const fetchCompanies = async () => {
      try {
        const response = await axiosConfig.get("/companies");
        const sortedCompanies = response.data.sort((a, b) =>
          a.company_name.localeCompare(b.company_name)
        ); // Sort alphabetically
        setCompanies(sortedCompanies);
      } catch (error) {
        console.error("Error fetching companies:", error);
      }
    };

    fetchCompanies();
  }, []);

  // Function to handle deleting an enquiry product
  const handleProductDelete = (enquiryProductId) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      axiosConfig
        .delete(`/enquirie-product/${enquiryProductId}`)
        .then((response) => {
          console.log("Product deleted successfully:", response.data);
          // Optionally, you can remove the deleted product from the state
          setEnquiryProducts((prevProducts) =>
            prevProducts.filter(
              (product) => product.enquiry_product_id !== enquiryProductId
            )
          );
        })
        .catch((error) => {
          console.error(
            "Error deleting product:",
            error.response || error.message
          );
        });
    }
  };

  const handleProductEdit = (productId) => {
    const productData = enquiryProducts.find(
      (product) => product.enquiry_product_id === productId
    );

    if (productData) {
      setFormData({
        product: productData.product_name,
        productId: productData.product_id, // Include product_id for editing
        modelNo: productData.model_no,
        qty: productData.quantity,
        orderValue: productData.order_value,
        description: productData.description,
      });
      setSelectedProduct(productId); // Set the selected product ID
      setIsEditing(true); // Enable edit mode
      setIsModalOpen(true); // Open modal
    }
  };

  async function contact(companyId) {
    let concatFile = {
      company_id: companyId,
      name: Name,
      designation: Designation,
      email: EmailID,
      phone: ContactNumber,
      department: Department,
    };
    console.log("concatFile:", concatFile);
    // await axiosConfig.post(`/add/companies_contact`, concatFile);
  }

  const saveEnquiry = async () => {
    if (isEditMode) {
      console.log("Update enquiry");

      try {
        console.log("Nextfollowup:", Nextfollowup);
        const formattedDate = Nextfollowup
          ? Nextfollowup.toISOString().split("T")[0]
          : "";

        // Log the formatted date to ensure it's correct
        console.log("Formatted Date:", formattedDate);

        // Create the body for the PUT request
        const requestBody = {
          customer_name: Name,
          email: EmailID,
          contact: ContactNumber,
          product_enquired: Productenquired,
          status: active,
          total: Total, // Add the Total field
          notes: Notes, // Add the Notes field
          assigned_to: Assigned, // Add the Assigned field
          date: NextFollowupAction, // Add the NextFollowupAction field
          chalanges: Chalanges,
          requirement_type: Requirement,
          stages: stages,
          win_possibilities: winPossibilities,
          competitor: Competitor,
          priority: Priority,
          next_follow_up: formattedDate,
          products: enquiryProducts,
        };

        console.log("Request Body:", requestBody);

        // Construct the URL without query parameters
        const url = `/update/${enquiryID}`;

        // Perform the PUT request using axiosConfig
        const response = await axiosConfig.put(url, requestBody);

        // Handle the successful update
        console.log("Update response:", response.data);
        // alert("Enquiry updated successfully");
      } catch (error) {
        // Handle any errors during the update
        console.error("Error updating enquiry:", error);
        if (error.response) {
          console.error("Response data:", error.response.data);
          console.error("Response status:", error.response.status);
        }
        alert("Failed to update enquiry. Please try again.");
      }
    } else {
      console.log("Create enquiry");

      // Handle add logic here (if this is for creating an enquiry)
      // You may call other functions for creating an enquiry if needed
      await company(); // Example function, you can replace it with actual logic
    }
  };

  const handleContactChange = (index, field, value) => {
    const updatedContacts = [...contacts];
    updatedContacts[index][field] = value;
    setContacts(updatedContacts);
  };

  const getCompanyDetail = localStorage.getItem("companyDetails");
  // console.log("rajjjjj ", getCompanyDetail);
  const parsedCompanyDetail = JSON.parse(getCompanyDetail);
  // console.log("parsedCompanyDetail::", JSON.parse(getCompanyDetail));

  const handleChangetypes = async (event) => {
    setcompanyname(event.target.value);
    const companyName = event.target.value;
    console.log("handleChangetypesSelectedValue:", companyName);

    try {
      const response = await axiosConfig.get(
        `/companies/details/${companyName}`
      );
      const companyDetails = response.data;

      console.log("companyDetails:", companyDetails);

      if (companyDetails) {
        // Set the company ID and company address ID
        setCompanyId(companyDetails.company_id || "");
        setCompanyAddressId(companyDetails.address?.company_address_id || ""); // Adjust property names if needed
        // Populate contact and other relevant details
        setname(companyDetails.contact?.name || "");
        setEmailID(companyDetails.contact?.email || "");
        setContactNumber(companyDetails.contact?.phone || "");
        setSource(companyDetails.source || ""); // Adjust the key if different in response
        setCity(companyDetails.address?.city || "");

        // Populate the state with the fetched company details (if needed)
        setDoorNo(companyDetails.address?.door_no || "");
        setstreetname(companyDetails.address?.street_name || "");
        setStreetLine(companyDetails.address?.street_line_2 || "");
        setDistrict(companyDetails.address?.district || "");
        setAreaTerritory(companyDetails.territory || "");
        setState(companyDetails.address?.state || "");
        setPincode(companyDetails.address?.postal_code || "");
        setsegment(companyDetails.segment || "");

        // Populate contact details
        const newContact = {
          Name: companyDetails.contact?.name || "",
          Designation: companyDetails.contact?.designation || "",
          EmailID: companyDetails.contact?.email || "",
          ContactNumber: companyDetails.contact?.phone || "",
        };

        // Update the state for contacts if needed
        setContacts([newContact]);
      } else {
        // Optionally clear input fields if no data is found
        // clearAllInputs();
      }
    } catch (error) {
      console.error("Error fetching company details: ", error);
      // Optionally clear input fields on error
      // clearAllInputs();
    }
  };

  const handleAddProduct = () => {
    const updatedProduct = {
      product_name: formData.productName, // Updated product name
      product_id: formData.productId, // Ensure product_id is updated
      model_no: formData.modelNo,
      quantity: formData.qty,
      order_value: formData.orderValue,
      description: formData.description,
      // enquiry_product_id: selectedProduct || new Date().getTime(), // Unique ID for new product
    };

    if (isEditing) {
      // Update existing product in the array
      setEnquiryProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.enquiry_product_id === selectedProduct
            ? updatedProduct
            : product
        )
      );
    } else {
      // Add new product to the array
      setEnquiryProducts((prevProducts) => [...prevProducts, updatedProduct]);
    }

    // Clear form and reset state
    setFormData({
      product: "",
      productId: "", // Clear product ID field
      modelNo: "",
      qty: 0,
      orderValue: 0,
      description: "",
    });
    setIsEditing(false);
    setSelectedProduct(null);
    setIsModalOpen(false);
  };

  // Fetch follow-ups
  const fetchFollowUps = async () => {
    try {
      const response = await axiosConfig.get(
        `/follow-ups/by-enquiry/${enquiryID}`
      );
      console.log("Follow-ups:", response.data);
      setFollowUps(response.data);
    } catch (error) {
      console.error("Error fetching follow-up data:", error);
    }
  };
  useEffect(() => {
    console.log("useEffect called.");

    if (enquiryID) {
      const action = localStorage.getItem("action");
      setIsEditMode(action === "edit");

      // Fetch enquiry details
      axiosConfig
        .get(`/enquiries/details/${enquiryID}`)
        .then((response) => {
          const data = response.data;
          setEnquiryDetails(data); // Store the response data if needed
          setEnquiryProducts(data.products || []);

          // Populate other fields from the response
          if (data) {
            setCompanyId(data.company_id || "");
            setCompanyAddressId(data.company_address_id || "");
            setcompanyname(data.masterCompany?.company_name || "");
            setsegment(data.masterCompany?.segment || "");
            setType(data.masterCompany?.type || "");
            setAreaTerritory(data.masterCompany?.territory || "");
            setCategory(data.masterCompany?.category || "");
            setUser(data.customer_name || "");
            setEmailID(data.masterCompany.contact.email || "");
            setContactNumber(data.contact || "");
            setTotal(data.total || "");
            setNotes(data.notes || "");
            setAssigned(data.assigned_to || "");
            setChalanges(data.chalanges || "");
            setRequirement(data.requirement_type || "");
            setStages(data.stages || "");
            setWinPossibilities(data.win_possibilities || "");
            setCompetitor(data.competitor || "");
            setPriority(data.priority || "");
            setActive(data.status || "");

            // Address details
            const address = data.masterCompany?.address;
            if (address) {
              setDoorNo(address.door_no || "");
              setstreetname(address.street_name || "");
              setStreetLine(address.street_line_2 || "");
              setDistrict(address.district || "");
              setCity(address.city || "");
              setState(address.state || "");
              setPincode(address.postal_code || "");
              setSource(address.source || "");
            }

            // Contact details
            const contact = data.masterCompany?.contact;
            if (contact) {
              setContacts([
                {
                  Name: contact.name || "",
                  Designation: contact.designation || "",
                  EmailID: contact.email || "",
                  ContactNumber: contact.phone || "",
                  Department: contact.department || "",
                },
              ]);
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching enquiry details:", error);
        });

      fetchFollowUps();
    } else {
      console.error("Enquiry ID is not provided.");
    }
  }, [enquiryID]);

  const handleFollowUpDelete = async (followUpId) => {
    // Confirm deletion
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this follow-up?"
    );
    if (!isConfirmed) return;

    try {
      // Send DELETE request to the API
      const response = await axiosConfig.delete(`/follow-ups/${followUpId}`);
      console.log("Follow-up deleted successfully:", response.data);

      // Refresh the list of follow-ups after deletion
      fetchFollowUps();
    } catch (error) {
      console.error("Error deleting follow-up:", error);
    }
  };

  const handleFollowUpEdit = async (followUp) => {
    try {
      // Fetch the follow-up details by follow_up_id
      const response = await axiosConfig.get(
        `/follow-ups/${followUp.follow_up_id}`
      );
      console.log("Follow-up Details:", response.data);

      // Set the data into formData
      setFormData({
        ...formData,
        follow_up_id: response.data.follow_up_id, // Set the follow-up ID
        contactType: response.data.contact_type,
        followUpStage: response.data.follow_up_stage,
        nextFollowupDate: response.data.nextFollowDate,
        FollowNotes: response.data.notes || "",
        createdDate: response.data.created_date,
      });

      // Set editing state to true
      setIsEditing(true); // Ensure you're in edit mode

      // Open the modal
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching follow-up details:", error);
    }
  };

  const handleAddFollowup = async () => {
    try {
      // Check if required fields are missing (except for escalate)
      if (
        !formData.contactType ||
        !formData.followUpStage ||
        !formData.nextFollowupDate
      ) {
        // Alert the user if any required field is missing
        alert("Please fill in all required fields before submitting.");
        return; // Exit the function without submitting
      }

      const postData = {
        enquiry: {
          enquiry_id: enquiryID, // Example enquiry_id, update accordingly
        },
        created_date: formData.createdDate,
        status: "Pending", // Set the initial status
        contact_type: formData.contactType,
        follow_up_stage: formData.followUpStage,
        nextFollowDate: formData.nextFollowupDate,
        escalate: formData.escalate, // It is allowed to be null or undefined
        notes: formData.notes,
      };

      if (isEditing) {
        // Update the existing follow-up (PUT request)
        const updateResponse = await axiosConfig.put(
          `/follow-ups/${formData.follow_up_id}`,
          postData
        );
        console.log("Follow-up updated successfully:", updateResponse.data);
      } else {
        // Create a new follow-up (POST request)
        const postResponse = await axiosConfig.post("follow-ups", postData);
        console.log("Follow-up added successfully:", postResponse.data);
      }

      // Close the modal after submitting
      setIsModalOpen(false);
      setIsEditing(false); // Reset editing state after saving

      // Reset formData
      setFormData({
        contactType: "",
        followUpStage: "",
        nextFollowupDate: "",
        escalate: false,
        notes: "",
        createdDate: new Date().toISOString().split("T")[0],
      });

      // Fetch updated follow-up data
      fetchFollowUps();
    } catch (error) {
      console.error("Error submitting follow-up:", error);
    }
  };
  return (
    <div>
      <Header />
      <form>
      <h2 className="basic-info">History</h2>
      <div className="product-row">
        <div className="table1">
          <table className="table-product">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Type of Contact</th>
                <th>Follow-up Stage</th>
                <th>Follow-up Date</th>
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {followUps.map((followUp, index) => (
                      <tr key={followUp.follow_up_id}>
                        <td>{index + 1}</td>
                        <td>{followUp.created_date}</td>
                        <td>{followUp.contact_type}</td>
                        <td>{followUp.follow_up_stage}</td>
                        <td>{followUp.nextFollowDate}</td>
                        <td>{followUp.notes || "N/A"}</td>
                  <td>
                    <BsTrash3Fill
                      className="trash-icon"
                      onClick={() => handleFollowUpDelete(followUp.follow_up_id)}
                    />
                    <BsPencilFill
                      className="edit-icon"
                      onClick={() => handleFollowUpEdit(followUp)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          type="button"
          onClick={openModal}         
          className="add-product-button"
        >
           Add Follow-up
        </button>

        {/* {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal-container">
              <div className="modal-header">
                <h2 className="modal-title">Add Product</h2>
                <div className="close-modal" onClick={handleClose}>
                  ×
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label className="form-label">Product</label>
                  <input
                    onClick={handleDropdownClick}
                    type="text"
                    value={formData.productName}
                    onChange={handleProductChange}
                    className="input-field"
                    placeholder="Select a product"
                    readOnly
                  />
                  <span className="dropdown-icon">▼</span>{" "}
                  {isDropdownOpen && (
                    <div className="dropdown-list-1">
                      {products.map((product) => (
                        <div
                        key={product.id}
                        onClick={() => handleProductChange(product)}
                          className="dropdown-item"
                        >
                            {product.product_name}
                            </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label className="form-label">Model No</label>
                  <input
                    type="text"
                    name="modelNo"
                    value={formData.modelNo}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Quantity</label>
                  <input
                    type="number"
                    name="qty"
                    value={formData.qty}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Order Value</label>
                  <input
                    type="number"
                    name="orderValue"
                    value={formData.orderValue}
                    onChange={handleInputChange}
                    className="input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="form-label">Description</label>
                  <textarea
                    name="description"
                    value={formData.description}
                    onChange={handleInputChange}
                    className="textarea-field"
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={handleAddProduct}
                    className="submit-button"
                  >
                    {isEditing ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )} */}
      </div>
    </form>
    <form>
      <h2 className="follow-info-title"> Enquiry Details</h2>

      <div className="follow-info-container">
        {/* Row 1 */}
        <div className="follow-row">
          <label className="folloup-label">
          Enquiry ID
          </label>
          <input
            type="text"
            value={enquiryID || ""}
            readOnly
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">  Date</label>
          <input
            type="date"
            value={Date || ""}
            onChange={(e) => setDeadline(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Assigned To</label>
          <input
            type="text"
            value={Assigned || ""}
            onChange={(e) => setAssigned(e.target.value)}
            className="folloup-input"
          />
        </div>

        {/* Row 2 */}
        <div className="follow-row">
          <label className="folloup-label">
          Company Name<span className="required">*</span>
          </label>
          <input
            type="text"
            value={companyname || ""}
            onChange={(e) => setcompanyname(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Address</label>
          <input
            type="text"
            value={streetname}
            onChange={(e) => setDoorNo(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label"> Category</label>
          <input
            type="text"
            value={Category || ""}
            onChange={(e) => setCategory(e.target.value)}
            className="folloup-input"
          />
        </div>

        {/* Row 3 */}
        <div className="follow-row">
          <label className="folloup-label">Contact Person</label>
          <input
            type="text"
           
            value={Name}
            onChange={(e) => {
              const updatedContacts = [...Name];
              updatedContacts[0].Name = e.target.value;
              setContacts(updatedContacts);
            }}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Mobile</label>
          <input
            type="text"
            value={ContactNumber || ""}
            onChange={(e) => setContactNumber(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Email ID</label>
          <input
            type="text"
            value={EmailID || ""}
            onChange={(e) => setEmailID(e.target.value)}
            className="folloup-input"
          />
        </div>

        {/* Row 4 - Pincode */}
        <div className="follow-row">
          <label className="folloup-label">Notes</label>
          <textarea
            id="notes"
            value={Notes}
                    onChange={(e) => setNotes(e.target.value)}
            className="folloup-area"
          />
        </div>
      </div>
    </form>
    <form>
      <h2 className="follow-info-title"> Classification</h2>

      <div className="follow-info-container">
        {/* Row 1 */}
        <div className="follow-row">
          <label className="folloup-label">
          Requirement Type
          </label>
          <input
            type="text"
            value={Requirement}
            onChange={(e) => setRequirement(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Stages</label>
          <input
            type="text"
            value={stages}
            onChange={(e) => setStages(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Priority</label>
          <input
            type="text"
            value={Priority}
            onChange={(e) => setPriority(e.target.value)}
            className="folloup-input"
          />
        </div>

        {/* Row 2 */}
        <div className="follow-row">
          <label className="folloup-label">
          Competitor
          </label>
          <input
            type="text"
            value={Competitor}
            onChange={(e) => setCompetitor(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label">Win Possibility</label>
          <input
            type="text"
            value={winPossibilities}
            onChange={(e) => setWinPossibilities(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label"> Challenges</label>
          <input
            type="text"
            value={Chalanges}
            onChange={(e) => setChalanges(e.target.value)}
            className="folloup-input"
          />
        </div>
        {/* Row 4 - Pincode */}
        <div className="follow-row">
          <label className="folloup-label">Notes</label>
          <textarea
            id="notes"
            value={Notes}
                    onChange={(e) => setNotes(e.target.value)}
            className="folloup-area"
          />
        </div>
      </div>
    </form>
    <form>
      <h2 className="basic-info">Product Details</h2>
      <div className="product-row">
        <div className="table1">
          <table className="table-product">
            <thead>
              <tr>
                <th>Serial No</th>
                <th>Product Name</th>
                <th>Model No</th>
                <th>Qty</th>
                <th>Order Value</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {enquiryProducts.map((product, index) => (
                      <tr key={product.enquiry_product_id}>
                        <td>{index + 1}</td>
                        <td>{product.product_name}</td>
                        <td>{product.model_no}</td>
                        <td>{product.quantity}</td>
                        <td>{product.order_value}</td>
                        <td>{product.description}</td>
                        <td>
                    <BsTrash3Fill
                      className="trash-icon"
                      onClick={() =>
                        handleProductDelete(product.enquiry_product_id)
                      }
                    />
                    <BsPencilFill
                      className="edit-icon"
                      onClick={() =>
                        handleProductEdit(product.enquiry_product_id)
                      }                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="follow-row">
          <label className="folloup-label">Total</label>
          <input
            type="text"
            value={Total}
            onChange={(e) => setTotal(e.target.value)}
            className="folloup-input"
          />
        </div>
        {/* <button
          type="button"
          onClick={openModalWithDelay}
          className="add-product-button"
        >
          Add Product
        </button> */}

        {isModalOpen && (
          <div className="follow-modal-overlay">
            <div className="follow-modal-container">
              <div className="follow-modal-header">
                <h2 className="follow-modal-title">NextFollowup</h2>
                <div className="follow-close-modal" onClick={handleClose}>
                  ×
                </div>
              </div>
              <form>
                <div className="form-group">
                  <label className="follow-form-label"> Created Date</label>
                  <input
                    type="text"
                    name="modelNo"
                    value={formData.createdDate}    
                    className="follow-input-field"
                  />
                </div>

                <div className="form-group">
                  <label className="follow-form-label">  Contact Type</label>
                  <select
                   name="contactType"
                   value={formData.contactType}
                   onChange={(e) => setFormData({ ...formData, contactType: e.target.value })}
                    className="follow-input-field"
                  >
                     <option value="">Select Contact Type</option>
                              <option value="mail">Mail</option>
                              <option value="phone_call">Phone Call</option>
                              <option value="personal_visit">
                                Personal Visit
                              </option>
                              <option value="whatsapp">WhatsApp</option>
                            </select>
                </div>

                <div className="form-group">
                  <label className="follow-form-label"> Follow up stages</label>
                  <select
                   name="followUpStage"
                   value={formData.followUpStage}
                   onChange={(e) => setFormData({ ...formData, followUpStage: e.target.value })}
                    className="follow-input-field"
                  >
                    <option value="">Follow-up stages</option>
                              <option value="model_arrive">Model Arrive</option>
                              <option value="demo_1">Demo I</option>
                              <option value="quotation">Quotation</option>
                              <option value="demo_II">Demo II</option>
                              <option value="srpf">SPRF</option>
                              <option value="srpf_approve">
                                SPRF Approval Pending
                              </option>
                              <option value="RQF">Changes in RQF</option>
                            </select>
                </div>

                <div className="form-group">
                  <label className="follow-form-label">Escalate</label>
                  <input
                   type="checkbox"
                   name="escalate"
                   checked={formData.escalate}
                   onChange={handleInputChange}
                    className="follow-input"
                  />
                </div>

                <div className="form-group">
                  <label className="follow-form-label">Next Follow-up</label>
                  <input
                  type="date"
                  name="nextFollowupDate"
                  value={formData.nextFollowupDate}
                  onChange={(e) => setFormData({ ...formData, nextFollowupDate: e.target.value })}
                    className="follow-input-field"
                  />
                </div>
                <div className="form-group">
                  <label className="follow-form-label">Notes </label>
                  <textarea
                   name="notes"
                   value={formData.notes || ""} 
                   onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                    className="textarea-field"
                  />
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={handleAddFollowup}
                    className="submit-button"
                  >
                    {isEditing ? "Update" : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </form>
    <form>
      <h2 className="follow-info-title"> </h2>

      <div className="follow-info-container">
        {/* Row 1 */}
        <div className="follow-row">
          <label className="folloup-label">
          Date          </label>
          <input
           type="date"
           value={Date}
           onChange={(e) => setDeadline(e.target.value)}
            className="folloup-input"
          />
        </div>
        <div className="follow-row">
          <label className="folloup-label"> Assigned To</label>
          <input
            type="text"
            value={Assigned}
            onChange={(e) => setAssigned(e.target.value)}
            className="folloup-input"
          />
        </div>
      </div>
    </form>
    <div className="buttons">
  <Button
    className="primary-button"
    onClick={() => {
      setcreatefileSave(true);
      if (isEditMode) {
        saveEnquiry();
      } else {
        enquiry(companyId, companyAddressId);
      }
    }}
  >
    {isEditMode ? "Update" : "Submit"}
  </Button>
</div>

<Dialog
  open={createFileSave}
  onClose={() => setcreatefileSave(false)}
  PaperProps={{ className: "dialog-paper" }}
>
  <DialogContent dividers className="dialog-content">
    <img
      src="https://cdn-icons-png.flaticon.com/512/190/190411.png"
      alt="Success Icon"
      className="success-icon"
    />
    <span className="success-message">
      Enquiry ID: {enquiryId} Submitted Successfully...!!!
    </span>
  </DialogContent>
  <DialogActions className="dialog-actions">
    <Button
      className="ok-button"
      onClick={addNavigateButton}
      variant="contained"
    >
      Ok
    </Button>
  </DialogActions>
</Dialog>

<div className="reset-button">
  <Button
    className="reset-btn"
    onClick={() => {
      // clearAllInputs();
    }}
  >
    Reset
  </Button>
</div>
    </div>
    
  );
}
export default FollowUp;
