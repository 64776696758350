import React, { useState } from "react";
import "../../css/Product.css"; // Custom CSS for styling

function ProductForm() {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productDetails, setProductDetails] = useState({
    type: "",
    partNo: "",
    hsnNo: "",
    unit: "",
  });

  // Mock product list for search suggestions
  const productList = [
    { product_id: 1, product_name: "Product A" },
    { product_id: 2, product_name: "Product B" },
    { product_id: 3, product_name: "Product C" },
  ];

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    if (term) {
      const results = productList.filter((product) =>
        product.product_name.toLowerCase().includes(term.toLowerCase())
      );
      setFilteredProducts(results);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleProductSelect = (product) => {
    setSearchTerm(product.product_name);
    setFilteredProducts([]);
  };

  return (
        <form>
            <h2 className="product-hea">Create New Product</h2>

          <div className="contain">
            {/* Product Name Field with Search Suggestion */}
            <div className="inroll-row">
              <label className="basic-text" htmlFor="productSearch">Product Name<span className="mandatory">*</span></label>
              <input
                type="text"
                id="productSearch"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search product name"
                 className="basic-input"
              />
              {filteredProducts.length > 0 && (
                <ul className="product-suggestions">
                  {filteredProducts.map((product) => (
                    <li
                      key={product.product_id}
                      onClick={() => handleProductSelect(product)}
                    >
                      {product.product_name}
                    </li>
                  ))}
                </ul>
              )}
            </div>

            {/* Type Field */}
            <div className="inroll-row">
              <label className="basic-text" >Type</label>
              <input
                type="text"
                id="type"
                value={productDetails.type}
                 className="basic-input"
                onChange={(e) =>
                  setProductDetails({ ...productDetails, type: e.target.value })
                }
                placeholder="Enter type"
              />
            </div>

            {/* Part No Field */}
            <div className="inroll-row">
              <label className="basic-text" htmlFor="partNo">Part No</label>
              <input
                type="text"
                id="partNo"
                value={productDetails.partNo}
                 className="basic-input"
                onChange={(e) =>
                  setProductDetails({ ...productDetails, partNo: e.target.value })
                }
                placeholder="Enter part no"
              />
            </div>

            {/* HSN No Field */}
            <div className="inroll-row">
              <label className="basic-text" htmlFor="hsnNo">HSN No</label>
              <input
                type="text"
                id="hsnNo"
                 className="basic-input"
                value={productDetails.hsnNo}
                onChange={(e) =>
                  setProductDetails({ ...productDetails, hsnNo: e.target.value })
                }
                placeholder="Enter HSN No"
              />
            </div>

            {/* Unit Field */}
            <div className="inroll-row">
              <label className="basic-text"  htmlFor="unit">Unit</label>
              <input
                type="text"
                id="unit"
                value={productDetails.unit}
                 className="basic-input"
                onChange={(e) =>
                  setProductDetails({ ...productDetails, unit: e.target.value })
                }
                placeholder="Enter unit"
              />
            </div>
          </div>
        </form>
  );
}

export default ProductForm;
