import React from 'react';

function UnauthorizedUser() {

  return (
    <div style={styles.container}>
      <div style={styles.box}>
        <p style={styles.message}>User not authorized. Please contact admin.</p>
      </div>
    </div>
  );

}

const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundColor: '#f5f5f5',
    },
    box: {
      padding: '20px 40px',
      borderRadius: '8px',
      backgroundColor: '#ffffff',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      textAlign: 'center',
    },
    message: {
      fontSize: '18px',
      color: '#333',
    },
};

export default UnauthorizedUser;