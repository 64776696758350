// Header.js
import React from 'react';
import '../css/Header.css'


const Header = () => {
  return (
    <div className="header">
      <div className="header-content">
        <h2 className='header-title ' >
        {
             window.location.pathname === "/dashboard" ? "Customer Information"
             :window.location.pathname === "/enquiry" ? "Enquiry Management"
             :window.location.pathname === "/followupManagement" ? "FollowUp Management"
             :window.location.pathname === "/followup" ? "Follow Up"
             :window.location.pathname === "/quotation" ? "Quotation Information"
             :window.location.pathname === "/closure" ? "Closure Management"
             :window.location.pathname === "/custom" ? "Customer Management"
             :window.location.pathname === "/enquire" ? "Enquiry Management"
             :window.location.pathname === "/enquiryManagement" ? "Enquiry Management"
             :window.location.pathname === "/quotations" ? "Quotation Management"
             :window.location.pathname === "/order" ? " Order Management"
             :window.location.pathname === "/orders" ? " Order Management"
             :window.location.pathname === "/pay" ? " Payment status tracking"
             :window.location.pathname === "/payment" ? " Payment status tracking "
             :window.location.pathname === "/incentive" ? " Incentive Management "
             :window.location.pathname === "/incentivemanage" ? " Incentive Management "
             :''
        }
        </h2> 
        {/* <div className='admin'>
        <div className='Avatarimg'>
        <img alt="Remy Sharp" src={avatar} style={{padding:'3px',width:'40px',height:'40px',marginRight:'30px'}}/>
        </div>
       <div className='avatar'>
       <text >Admin</text>
       </div>
       </div> */}
      </div>
    </div>
  );
};


export default Header;
